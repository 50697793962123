!function(t, e) {
    var i = function(n, h) {
        "use strict";
        if (!h.getElementsByClassName)
            return;
        var c, l = h.documentElement, r = n.Date, o = n.HTMLPictureElement, s = "addEventListener", d = "getAttribute", e = n[s], f = n.setTimeout, i = n.requestAnimationFrame || f, a = n.requestIdleCallback, p = /^picture$/i, u = ["load", "error", "lazyincluded", "_lazyloaded"], m = {}, g = Array.prototype.forEach, v = function(t, e) {
            return m[e] || (m[e] = new RegExp("(\\s|^)" + e + "(\\s|$)")),
            m[e].test(t[d]("class") || "") && m[e]
        }, y = function(t, e) {
            v(t, e) || t.setAttribute("class", (t[d]("class") || "").trim() + " " + e)
        }, b = function(t, e) {
            var i;
            (i = v(t, e)) && t.setAttribute("class", (t[d]("class") || "").replace(i, " "))
        }, x = function(e, i, t) {
            var n = t ? s : "removeEventListener";
            t && x(e, i),
            u.forEach(function(t) {
                e[n](t, i)
            })
        }, w = function(t, e, i, n, o) {
            var r = h.createEvent("CustomEvent");
            return r.initCustomEvent(e, !n, !o, i || {}),
            t.dispatchEvent(r),
            r
        }, E = function(t, e) {
            var i;
            !o && (i = n.picturefill || c.pf) ? i({
                reevaluate: !0,
                elements: [t]
            }) : e && e.src && (t.src = e.src)
        }, S = function(t, e) {
            return (getComputedStyle(t, null) || {})[e]
        }, C = function(t, e, i) {
            for (i = i || t.offsetWidth; i < c.minSize && e && !t._lazysizesWidth; )
                i = e.offsetWidth,
                e = e.parentNode;
            return i
        }, z = (xt = [],
        wt = [],
        Et = function() {
            var t = xt;
            for (xt = wt,
            bt = !(yt = !0); t.length; )
                t.shift()();
            yt = !1
        }
        ,
        St = function(t, e) {
            yt && !e ? t.apply(this, arguments) : (xt.push(t),
            bt || (bt = !0,
            (h.hidden ? f : i)(Et)))
        }
        ,
        St._lsFlush = Et,
        St), t = function(i, t) {
            return t ? function() {
                z(i)
            }
            : function() {
                var t = this
                  , e = arguments;
                z(function() {
                    i.apply(t, e)
                })
            }
        }, _ = function(t) {
            var e, i, n = function() {
                e = null,
                t()
            }, o = function() {
                var t = r.now() - i;
                t < 99 ? f(o, 99 - t) : (a || n)(n)
            };
            return function() {
                i = r.now(),
                e || (e = f(o, 99))
            }
        }, T = (et = /^img$/i,
        it = /^iframe$/i,
        nt = "onscroll"in n && !/glebot/.test(navigator.userAgent),
        ot = 0,
        rt = 0,
        st = -1,
        at = function(t) {
            rt--,
            t && t.target && x(t.target, at),
            (!t || rt < 0 || !t.target) && (rt = 0)
        }
        ,
        ut = function(t, e) {
            var i, n = t, o = "hidden" == S(h.body, "visibility") || "hidden" != S(t, "visibility");
            for (H -= e,
            Y += e,
            B -= e,
            U += e; o && (n = n.offsetParent) && n != h.body && n != l; )
                (o = 0 < (S(n, "opacity") || 1)) && "visible" != S(n, "overflow") && (i = n.getBoundingClientRect(),
                o = U > i.left && B < i.right && Y > i.top - 1 && H < i.bottom + 1);
            return o
        }
        ,
        ht = function() {
            var t, e, i, n, o, r, s, a, u;
            if ((j = c.loadMode) && rt < 8 && (t = F.length)) {
                e = 0,
                st++,
                null == X && ("expand"in c || (c.expand = 500 < l.clientHeight && 500 < l.clientWidth ? 500 : 370),
                $ = c.expand,
                X = $ * c.expFactor),
                ot < X && rt < 1 && 2 < st && 2 < j && !h.hidden ? (ot = X,
                st = 0) : ot = 1 < j && 1 < st && rt < 6 ? $ : 0;
                for (; e < t; e++)
                    if (F[e] && !F[e]._lazyRace)
                        if (nt)
                            if ((a = F[e][d]("data-expand")) && (r = 1 * a) || (r = ot),
                            u !== r && (N = innerWidth + r * Q,
                            W = innerHeight + r,
                            s = -1 * r,
                            u = r),
                            i = F[e].getBoundingClientRect(),
                            (Y = i.bottom) >= s && (H = i.top) <= W && (U = i.right) >= s * Q && (B = i.left) <= N && (Y || U || B || H) && (R && rt < 3 && !a && (j < 3 || st < 4) || ut(F[e], r))) {
                                if (gt(F[e]),
                                o = !0,
                                9 < rt)
                                    break
                            } else
                                !o && R && !n && rt < 4 && st < 4 && 2 < j && (O[0] || c.preloadAfterLoad) && (O[0] || !a && (Y || U || B || H || "auto" != F[e][d](c.sizesAttr))) && (n = O[0] || F[e]);
                        else
                            gt(F[e]);
                n && !o && gt(n)
            }
        }
        ,
        V = ht,
        J = 0,
        Z = 666,
        K = function() {
            G = !1,
            J = r.now(),
            V()
        }
        ,
        tt = a ? function() {
            a(K, {
                timeout: Z
            }),
            666 !== Z && (Z = 666)
        }
        : t(function() {
            f(K)
        }, !0),
        lt = function(t) {
            var e;
            (t = !0 === t) && (Z = 44),
            G || (G = !0,
            (e = 125 - (r.now() - J)) < 0 && (e = 0),
            t || e < 9 && a ? tt() : f(tt, e))
        }
        ,
        ct = function(t) {
            y(t.target, c.loadedClass),
            b(t.target, c.loadingClass),
            x(t.target, ft)
        }
        ,
        dt = t(ct),
        ft = function(t) {
            dt({
                target: t.target
            })
        }
        ,
        pt = function(t) {
            var e, i, n = t[d](c.srcsetAttr);
            (e = c.customMedia[t[d]("data-media") || t[d]("media")]) && t.setAttribute("media", e),
            n && t.setAttribute("srcset", n),
            e && ((i = t.parentNode).insertBefore(t.cloneNode(), t),
            i.removeChild(t))
        }
        ,
        mt = t(function(t, e, i, n, o) {
            var r, s, a, u, h, l;
            (h = w(t, "lazybeforeunveil", e)).defaultPrevented || (n && (i ? y(t, c.autosizesClass) : t.setAttribute("sizes", n)),
            s = t[d](c.srcsetAttr),
            r = t[d](c.srcAttr),
            o && (a = t.parentNode,
            u = a && p.test(a.nodeName || "")),
            l = e.firesLoad || "src"in t && (s || r || u),
            h = {
                target: t
            },
            l && (x(t, at, !0),
            clearTimeout(D),
            D = f(at, 2500),
            y(t, c.loadingClass),
            x(t, ft, !0)),
            u && g.call(a.getElementsByTagName("source"), pt),
            s ? t.setAttribute("srcset", s) : r && !u && (it.test(t.nodeName) ? function(e, i) {
                try {
                    e.contentWindow.location.replace(i)
                } catch (t) {
                    e.src = i
                }
            }(t, r) : t.src = r),
            (s || u) && E(t, {
                src: r
            })),
            t._lazyRace && delete t._lazyRace,
            b(t, c.lazyClass),
            z(function() {
                l && !t.complete || (l ? at(h) : rt--,
                ct(h))
            }, !0)
        }),
        gt = function(t) {
            var e, i = et.test(t.nodeName), n = i && (t[d](c.sizesAttr) || t[d]("sizes")), o = "auto" == n;
            (!o && R || !i || !t.src && !t.srcset || t.complete || v(t, c.errorClass)) && (e = w(t, "lazyunveilread").detail,
            o && I.updateElem(t, !0, t.offsetWidth),
            t._lazyRace = !0,
            rt++,
            mt(t, e, o, n, i))
        }
        ,
        vt = function() {
            if (!R)
                if (r.now() - q < 999)
                    f(vt, 999);
                else {
                    var t = _(function() {
                        c.loadMode = 3,
                        lt()
                    });
                    R = !0,
                    c.loadMode = 3,
                    lt(),
                    e("scroll", function() {
                        3 == c.loadMode && (c.loadMode = 2),
                        t()
                    }, !0)
                }
        }
        ,
        {
            _: function() {
                q = r.now(),
                F = h.getElementsByClassName(c.lazyClass),
                O = h.getElementsByClassName(c.lazyClass + " " + c.preloadClass),
                Q = c.hFac,
                e("scroll", lt, !0),
                e("resize", lt, !0),
                n.MutationObserver ? new MutationObserver(lt).observe(l, {
                    childList: !0,
                    subtree: !0,
                    attributes: !0
                }) : (l[s]("DOMNodeInserted", lt, !0),
                l[s]("DOMAttrModified", lt, !0),
                setInterval(lt, 999)),
                e("hashchange", lt, !0),
                ["focus", "mouseover", "click", "load", "transitionend", "animationend", "webkitAnimationEnd"].forEach(function(t) {
                    h[s](t, lt, !0)
                }),
                /d$|^c/.test(h.readyState) ? vt() : (e("load", vt),
                h[s]("DOMContentLoaded", lt),
                f(vt, 2e4)),
                F.length ? (ht(),
                z._lsFlush()) : lt()
            },
            checkElems: lt,
            unveil: gt
        }), I = (M = t(function(t, e, i, n) {
            var o, r, s;
            if (t._lazysizesWidth = n,
            n += "px",
            t.setAttribute("sizes", n),
            p.test(e.nodeName || ""))
                for (o = e.getElementsByTagName("source"),
                r = 0,
                s = o.length; r < s; r++)
                    o[r].setAttribute("sizes", n);
            i.detail.dataAttr || E(t, i.detail)
        }),
        P = function(t, e, i) {
            var n, o = t.parentNode;
            o && (i = C(t, o, i),
            (n = w(t, "lazybeforesizes", {
                width: i,
                dataAttr: !!e
            })).defaultPrevented || (i = n.detail.width) && i !== t._lazysizesWidth && M(t, o, n, i))
        }
        ,
        A = _(function() {
            var t, e = k.length;
            if (e)
                for (t = 0; t < e; t++)
                    P(k[t])
        }),
        {
            _: function() {
                k = h.getElementsByClassName(c.autosizesClass),
                e("resize", A)
            },
            checkElems: A,
            updateElem: P
        }), L = function() {
            L.i || (L.i = !0,
            I._(),
            T._())
        };
        var k, M, P, A;
        var F, O, R, D, j, q, N, W, H, B, U, Y, $, X, Q, V, G, J, Z, K, tt, et, it, nt, ot, rt, st, at, ut, ht, lt, ct, dt, ft, pt, mt, gt, vt;
        var yt, bt, xt, wt, Et, St;
        return function() {
            var t, e = {
                lazyClass: "lazyload",
                loadedClass: "lazyloaded",
                loadingClass: "lazyloading",
                preloadClass: "lazypreload",
                errorClass: "lazyerror",
                autosizesClass: "lazyautosizes",
                srcAttr: "data-src",
                srcsetAttr: "data-srcset",
                sizesAttr: "data-sizes",
                minSize: 40,
                customMedia: {},
                init: !0,
                expFactor: 1.5,
                hFac: .8,
                loadMode: 2
            };
            for (t in c = n.lazySizesConfig || n.lazysizesConfig || {},
            e)
                t in c || (c[t] = e[t]);
            n.lazySizesConfig = c,
            f(function() {
                c.init && L()
            })
        }(),
        {
            cfg: c,
            autoSizer: I,
            loader: T,
            init: L,
            uP: E,
            aC: y,
            rC: b,
            hC: v,
            fire: w,
            gW: C,
            rAF: z
        }
    }(t, t.document);
    t.lazySizes = i,
    "object" == typeof module && module.exports && (module.exports = i)
}(window),
function(c, r, t) {
    "use strict";
    var n, s, a, d = c.lazySizes && lazySizes.cfg || c.lazySizesConfig, e = r.createElement("img"), i = "sizes"in e && "srcset"in e, u = /\s+\d+h/g, o = (s = /\s+(\d+)(w|h)\s+(\d+)(w|h)/,
    a = Array.prototype.forEach,
    function(t) {
        var e = r.createElement("img")
          , i = function(t) {
            var e, i = t.getAttribute(lazySizesConfig.srcsetAttr);
            i && (i.match(s) && (e = "w" == RegExp.$2 ? RegExp.$1 / RegExp.$3 : RegExp.$3 / RegExp.$1) && t.setAttribute("data-aspectratio", e),
            t.setAttribute(lazySizesConfig.srcsetAttr, i.replace(u, "")))
        }
          , n = function(t) {
            var e = t.target.parentNode;
            e && "PICTURE" == e.nodeName && a.call(e.getElementsByTagName("source"), i),
            i(t.target)
        }
          , o = function() {
            e.currentSrc && r.removeEventListener("lazybeforeunveil", n)
        };
        t[1] && (r.addEventListener("lazybeforeunveil", n),
        e.onload = o,
        e.onerror = o,
        e.srcset = "data:,a 1w 1h",
        e.complete && o())
    }
    );
    if (d || (d = {},
    c.lazySizesConfig = d),
    d.supportsType || (d.supportsType = function(t) {
        return !t
    }
    ),
    !c.picturefill && !d.pf) {
        if (c.HTMLPictureElement && i)
            return r.msElementsFromPoint && o(navigator.userAgent.match(/Edge\/(\d+)/)),
            d.pf = function() {}
            ;
        var h, l, f, p, m, g, v, y, b, x, w, E, S;
        d.pf = function(t) {
            var e, i;
            if (!c.picturefill)
                for (e = 0,
                i = t.elements.length; e < i; e++)
                    n(t.elements[e])
        }
        ,
        m = function(t, e) {
            return t.w - e.w
        }
        ,
        g = /^\s*\d+px\s*$/,
        l = /(([^,\s].[^\s]+)\s+(\d+)w)/g,
        f = /\s/,
        p = function(t, e, i, n) {
            h.push({
                c: e,
                u: i,
                w: 1 * n
            })
        }
        ,
        y = function() {
            var t, i, e;
            y.init || (y.init = !0,
            addEventListener("resize", (i = r.getElementsByClassName("lazymatchmedia"),
            e = function() {
                var t, e;
                for (t = 0,
                e = i.length; t < e; t++)
                    n(i[t])
            }
            ,
            function() {
                clearTimeout(t),
                t = setTimeout(e, 66)
            }
            )))
        }
        ,
        b = function(t, e) {
            var i, n = t.getAttribute("srcset") || t.getAttribute(d.srcsetAttr);
            !n && e && (n = t._lazypolyfill ? t._lazypolyfill._set : t.getAttribute(d.srcAttr) || t.getAttribute("src")),
            t._lazypolyfill && t._lazypolyfill._set == n || (i = v(n || ""),
            e && t.parentNode && (i.isPicture = "PICTURE" == t.parentNode.nodeName.toUpperCase(),
            i.isPicture && c.matchMedia && (lazySizes.aC(t, "lazymatchmedia"),
            y())),
            i._set = n,
            Object.defineProperty(t, "_lazypolyfill", {
                value: i,
                writable: !0
            }))
        }
        ,
        x = function(t) {
            return c.matchMedia ? (x = function(t) {
                return !t || (matchMedia(t) || {}).matches
            }
            )(t) : !t
        }
        ,
        w = function(t) {
            var e, i, n, o, r, s, a, u, h, l;
            if (b(o = t, !0),
            (r = o._lazypolyfill).isPicture)
                for (i = 0,
                n = (e = t.parentNode.getElementsByTagName("source")).length; i < n; i++)
                    if (d.supportsType(e[i].getAttribute("type"), t) && x(e[i].getAttribute("media"))) {
                        o = e[i],
                        b(o),
                        r = o._lazypolyfill;
                        break
                    }
            return 1 < r.length ? (a = o.getAttribute("sizes") || "",
            a = g.test(a) && parseInt(a, 10) || lazySizes.gW(t, t.parentNode),
            r.d = (u = t,
            h = c.devicePixelRatio || 1,
            l = lazySizes.getX && lazySizes.getX(u),
            Math.min(l || h, 2.5, h)),
            !r.src || !r.w || r.w < a ? (r.w = a,
            s = function(t) {
                for (var e, i, n = t.length, o = t[n - 1], r = 0; r < n; r++)
                    if ((o = t[r]).d = o.w / t.w,
                    o.d >= t.d) {
                        !o.cached && (e = t[r - 1]) && e.d > t.d - .13 * Math.pow(t.d, 2.2) && (i = Math.pow(e.d - .6, 1.6),
                        e.cached && (e.d += .15 * i),
                        e.d + (o.d - t.d) * i > t.d && (o = e));
                        break
                    }
                return o
            }(r.sort(m)),
            r.src = s) : s = r.src) : s = r[0],
            s
        }
        ,
        (E = function(t) {
            if (!i || !t.parentNode || "PICTURE" == t.parentNode.nodeName.toUpperCase()) {
                var e = w(t);
                e && e.u && t._lazypolyfill.cur != e.u && (t._lazypolyfill.cur = e.u,
                e.cached = !0,
                t.setAttribute(d.srcAttr, e.u),
                t.setAttribute("src", e.u))
            }
        }
        ).parse = v = function(t) {
            return h = [],
            (t = t.trim()).replace(u, "").replace(l, p),
            h.length || !t || f.test(t) || h.push({
                c: t,
                u: t,
                w: 99
            }),
            h
        }
        ,
        n = E,
        d.loadedClass && d.loadingClass && (S = [],
        ['img[sizes$="px"][srcset].', "picture > img:not([srcset])."].forEach(function(t) {
            S.push(t + d.loadedClass),
            S.push(t + d.loadingClass)
        }),
        d.pf({
            elements: r.querySelectorAll(S.join(", "))
        }))
    }
}(window, document),
function(u) {
    "use strict";
    var h, t = u.createElement("img");
    !("srcset"in t) || "sizes"in t || window.HTMLPictureElement || (h = /^picture$/i,
    u.addEventListener("lazybeforeunveil", function(t) {
        var e, i, n, o, r, s, a;
        !t.defaultPrevented && !lazySizesConfig.noIOSFix && (e = t.target) && (n = e.getAttribute(lazySizesConfig.srcsetAttr)) && (i = e.parentNode) && ((r = h.test(i.nodeName || "")) || (o = e.getAttribute("sizes") || e.getAttribute(lazySizesConfig.sizesAttr))) && (s = r ? i : u.createElement("picture"),
        e._lazyImgSrc || Object.defineProperty(e, "_lazyImgSrc", {
            value: u.createElement("source"),
            writable: !0
        }),
        a = e._lazyImgSrc,
        o && a.setAttribute("sizes", o),
        a.setAttribute(lazySizesConfig.srcsetAttr, n),
        e.setAttribute("data-pfsrcset", n),
        e.removeAttribute(lazySizesConfig.srcsetAttr),
        r || (i.insertBefore(s, e),
        s.appendChild(e)),
        s.insertBefore(a, e))
    }))
}(document),
function(t) {
    "function" == typeof define && define.amd ? define(["jquery"], t) : "object" == typeof module && module.exports ? module.exports = t(require("jquery")) : t(jQuery)
}(function(l) {
    l.extend(l.fn, {
        validate: function(t) {
            if (this.length) {
                var n = l.data(this[0], "validator");
                return n || (this.attr("novalidate", "novalidate"),
                n = new l.validator(t,this[0]),
                l.data(this[0], "validator", n),
                n.settings.onsubmit && (this.on("click.validate", ":submit", function(t) {
                    n.settings.submitHandler && (n.submitButton = t.target),
                    l(this).hasClass("cancel") && (n.cancelSubmit = !0),
                    void 0 !== l(this).attr("formnovalidate") && (n.cancelSubmit = !0)
                }),
                this.on("submit.validate", function(i) {
                    function t() {
                        var t, e;
                        return !n.settings.submitHandler || (n.submitButton && (t = l("<input type='hidden'/>").attr("name", n.submitButton.name).val(l(n.submitButton).val()).appendTo(n.currentForm)),
                        e = n.settings.submitHandler.call(n, n.currentForm, i),
                        n.submitButton && t.remove(),
                        void 0 !== e && e)
                    }
                    return n.settings.debug && i.preventDefault(),
                    n.cancelSubmit ? (n.cancelSubmit = !1,
                    t()) : n.form() ? n.pendingRequest ? !(n.formSubmitted = !0) : t() : (n.focusInvalid(),
                    !1)
                })),
                n)
            }
            t && t.debug && window.console && console.warn("Nothing selected, can't validate, returning nothing.")
        },
        valid: function() {
            var t, e, i;
            return l(this[0]).is("form") ? t = this.validate().form() : (i = [],
            t = !0,
            e = l(this[0].form).validate(),
            this.each(function() {
                (t = e.element(this) && t) || (i = i.concat(e.errorList))
            }),
            e.errorList = i),
            t
        },
        rules: function(t, e) {
            var i, n, o, r, s, a, u = this[0];
            if (null != u && null != u.form) {
                if (t)
                    switch (n = (i = l.data(u.form, "validator").settings).rules,
                    o = l.validator.staticRules(u),
                    t) {
                    case "add":
                        l.extend(o, l.validator.normalizeRule(e)),
                        delete o.messages,
                        n[u.name] = o,
                        e.messages && (i.messages[u.name] = l.extend(i.messages[u.name], e.messages));
                        break;
                    case "remove":
                        return e ? (a = {},
                        l.each(e.split(/\s/), function(t, e) {
                            a[e] = o[e],
                            delete o[e],
                            "required" === e && l(u).removeAttr("aria-required")
                        }),
                        a) : (delete n[u.name],
                        o)
                    }
                return (r = l.validator.normalizeRules(l.extend({}, l.validator.classRules(u), l.validator.attributeRules(u), l.validator.dataRules(u), l.validator.staticRules(u)), u)).required && (s = r.required,
                delete r.required,
                r = l.extend({
                    required: s
                }, r),
                l(u).attr("aria-required", "true")),
                r.remote && (s = r.remote,
                delete r.remote,
                r = l.extend(r, {
                    remote: s
                })),
                r
            }
        }
    }),
    l.extend(l.expr[":"], {
        blank: function(t) {
            return !l.trim("" + l(t).val())
        },
        filled: function(t) {
            var e = l(t).val();
            return null !== e && !!l.trim("" + e)
        },
        unchecked: function(t) {
            return !l(t).prop("checked")
        }
    }),
    l.validator = function(t, e) {
        this.settings = l.extend(!0, {}, l.validator.defaults, t),
        this.currentForm = e,
        this.init()
    }
    ,
    l.validator.format = function(i, t) {
        return 1 === arguments.length ? function() {
            var t = l.makeArray(arguments);
            return t.unshift(i),
            l.validator.format.apply(this, t)
        }
        : (void 0 === t || (2 < arguments.length && t.constructor !== Array && (t = l.makeArray(arguments).slice(1)),
        t.constructor !== Array && (t = [t]),
        l.each(t, function(t, e) {
            i = i.replace(new RegExp("\\{" + t + "\\}","g"), function() {
                return e
            })
        })),
        i)
    }
    ,
    l.extend(l.validator, {
        defaults: {
            messages: {},
            groups: {},
            rules: {},
            errorClass: "error",
            pendingClass: "pending",
            validClass: "valid",
            errorElement: "label",
            focusCleanup: !1,
            focusInvalid: !0,
            errorContainer: l([]),
            errorLabelContainer: l([]),
            onsubmit: !0,
            ignore: ":hidden",
            ignoreTitle: !1,
            onfocusin: function(t) {
                this.lastActive = t,
                this.settings.focusCleanup && (this.settings.unhighlight && this.settings.unhighlight.call(this, t, this.settings.errorClass, this.settings.validClass),
                this.hideThese(this.errorsFor(t)))
            },
            onfocusout: function(t) {
                this.checkable(t) || !(t.name in this.submitted) && this.optional(t) || this.element(t)
            },
            onkeyup: function(t, e) {
                9 === e.which && "" === this.elementValue(t) || -1 !== l.inArray(e.keyCode, [16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 45, 144, 225]) || (t.name in this.submitted || t.name in this.invalid) && this.element(t)
            },
            onclick: function(t) {
                t.name in this.submitted ? this.element(t) : t.parentNode.name in this.submitted && this.element(t.parentNode)
            },
            highlight: function(t, e, i) {
                "radio" === t.type ? this.findByName(t.name).addClass(e).removeClass(i) : l(t).addClass(e).removeClass(i)
            },
            unhighlight: function(t, e, i) {
                "radio" === t.type ? this.findByName(t.name).removeClass(e).addClass(i) : l(t).removeClass(e).addClass(i)
            }
        },
        setDefaults: function(t) {
            l.extend(l.validator.defaults, t)
        },
        messages: {
            required: "This field is required.",
            remote: "Please fix this field.",
            email: "Please enter a valid email address.",
            url: "Please enter a valid URL.",
            date: "Please enter a valid date.",
            dateISO: "Please enter a valid date (ISO).",
            number: "Please enter a valid number.",
            digits: "Please enter only digits.",
            equalTo: "Please enter the same value again.",
            maxlength: l.validator.format("Please enter no more than {0} characters."),
            minlength: l.validator.format("Please enter at least {0} characters."),
            rangelength: l.validator.format("Please enter a value between {0} and {1} characters long."),
            range: l.validator.format("Please enter a value between {0} and {1}."),
            max: l.validator.format("Please enter a value less than or equal to {0}."),
            min: l.validator.format("Please enter a value greater than or equal to {0}."),
            step: l.validator.format("Please enter a multiple of {0}.")
        },
        autoCreateRanges: !1,
        prototype: {
            init: function() {
                this.labelContainer = l(this.settings.errorLabelContainer),
                this.errorContext = this.labelContainer.length && this.labelContainer || l(this.currentForm),
                this.containers = l(this.settings.errorContainer).add(this.settings.errorLabelContainer),
                this.submitted = {},
                this.valueCache = {},
                this.pendingRequest = 0,
                this.pending = {},
                this.invalid = {},
                this.reset();
                var i, n = this.groups = {};
                function t(t) {
                    !this.form && this.hasAttribute("contenteditable") && (this.form = l(this).closest("form")[0]);
                    var e = l.data(this.form, "validator")
                      , i = "on" + t.type.replace(/^validate/, "")
                      , n = e.settings;
                    n[i] && !l(this).is(n.ignore) && n[i].call(e, this, t)
                }
                l.each(this.settings.groups, function(i, t) {
                    "string" == typeof t && (t = t.split(/\s/)),
                    l.each(t, function(t, e) {
                        n[e] = i
                    })
                }),
                i = this.settings.rules,
                l.each(i, function(t, e) {
                    i[t] = l.validator.normalizeRule(e)
                }),
                l(this.currentForm).on("focusin.validate focusout.validate keyup.validate", ":text, [type='password'], [type='file'], select, textarea, [type='number'], [type='search'], [type='tel'], [type='url'], [type='email'], [type='datetime'], [type='date'], [type='month'], [type='week'], [type='time'], [type='datetime-local'], [type='range'], [type='color'], [type='radio'], [type='checkbox'], [contenteditable]", t).on("click.validate", "select, option, [type='radio'], [type='checkbox']", t),
                this.settings.invalidHandler && l(this.currentForm).on("invalid-form.validate", this.settings.invalidHandler),
                l(this.currentForm).find("[required], [data-rule-required], .required").attr("aria-required", "true")
            },
            form: function() {
                return this.checkForm(),
                l.extend(this.submitted, this.errorMap),
                this.invalid = l.extend({}, this.errorMap),
                this.valid() || l(this.currentForm).triggerHandler("invalid-form", [this]),
                this.showErrors(),
                this.valid()
            },
            checkForm: function() {
                this.prepareForm();
                for (var t = 0, e = this.currentElements = this.elements(); e[t]; t++)
                    this.check(e[t]);
                return this.valid()
            },
            element: function(t) {
                var e, i, n = this.clean(t), o = this.validationTargetFor(n), r = this, s = !0;
                return void 0 === o ? delete this.invalid[n.name] : (this.prepareElement(o),
                this.currentElements = l(o),
                (i = this.groups[o.name]) && l.each(this.groups, function(t, e) {
                    e === i && t !== o.name && (n = r.validationTargetFor(r.clean(r.findByName(t)))) && n.name in r.invalid && (r.currentElements.push(n),
                    s = r.check(n) && s)
                }),
                e = !1 !== this.check(o),
                s = s && e,
                this.invalid[o.name] = !e,
                this.numberOfInvalids() || (this.toHide = this.toHide.add(this.containers)),
                this.showErrors(),
                l(t).attr("aria-invalid", !e)),
                s
            },
            showErrors: function(e) {
                if (e) {
                    var i = this;
                    l.extend(this.errorMap, e),
                    this.errorList = l.map(this.errorMap, function(t, e) {
                        return {
                            message: t,
                            element: i.findByName(e)[0]
                        }
                    }),
                    this.successList = l.grep(this.successList, function(t) {
                        return !(t.name in e)
                    })
                }
                this.settings.showErrors ? this.settings.showErrors.call(this, this.errorMap, this.errorList) : this.defaultShowErrors()
            },
            resetForm: function() {
                l.fn.resetForm && l(this.currentForm).resetForm(),
                this.invalid = {},
                this.submitted = {},
                this.prepareForm(),
                this.hideErrors();
                var t = this.elements().removeData("previousValue").removeAttr("aria-invalid");
                this.resetElements(t)
            },
            resetElements: function(t) {
                var e;
                if (this.settings.unhighlight)
                    for (e = 0; t[e]; e++)
                        this.settings.unhighlight.call(this, t[e], this.settings.errorClass, ""),
                        this.findByName(t[e].name).removeClass(this.settings.validClass);
                else
                    t.removeClass(this.settings.errorClass).removeClass(this.settings.validClass)
            },
            numberOfInvalids: function() {
                return this.objectLength(this.invalid)
            },
            objectLength: function(t) {
                var e, i = 0;
                for (e in t)
                    t[e] && i++;
                return i
            },
            hideErrors: function() {
                this.hideThese(this.toHide)
            },
            hideThese: function(t) {
                t.not(this.containers).text(""),
                this.addWrapper(t).hide()
            },
            valid: function() {
                return 0 === this.size()
            },
            size: function() {
                return this.errorList.length
            },
            focusInvalid: function() {
                if (this.settings.focusInvalid)
                    try {
                        l(this.findLastActive() || this.errorList.length && this.errorList[0].element || []).filter(":visible").focus().trigger("focusin")
                    } catch (t) {}
            },
            findLastActive: function() {
                var e = this.lastActive;
                return e && 1 === l.grep(this.errorList, function(t) {
                    return t.element.name === e.name
                }).length && e
            },
            elements: function() {
                var e = this
                  , i = {};
                return l(this.currentForm).find("input, select, textarea, [contenteditable]").not(":submit, :reset, :image, :disabled").not(this.settings.ignore).filter(function() {
                    var t = this.name || l(this).attr("name");
                    return !t && e.settings.debug && window.console && console.error("%o has no name assigned", this),
                    this.hasAttribute("contenteditable") && (this.form = l(this).closest("form")[0]),
                    !(t in i || !e.objectLength(l(this).rules())) && (i[t] = !0)
                })
            },
            clean: function(t) {
                return l(t)[0]
            },
            errors: function() {
                var t = this.settings.errorClass.split(" ").join(".");
                return l(this.settings.errorElement + "." + t, this.errorContext)
            },
            resetInternals: function() {
                this.successList = [],
                this.errorList = [],
                this.errorMap = {},
                this.toShow = l([]),
                this.toHide = l([])
            },
            reset: function() {
                this.resetInternals(),
                this.currentElements = l([])
            },
            prepareForm: function() {
                this.reset(),
                this.toHide = this.errors().add(this.containers)
            },
            prepareElement: function(t) {
                this.reset(),
                this.toHide = this.errorsFor(t)
            },
            elementValue: function(t) {
                var e, i, n = l(t), o = t.type;
                return "radio" === o || "checkbox" === o ? this.findByName(t.name).filter(":checked").val() : "number" === o && void 0 !== t.validity ? t.validity.badInput ? "NaN" : n.val() : (e = t.hasAttribute("contenteditable") ? n.text() : n.val(),
                "file" === o ? "C:\\fakepath\\" === e.substr(0, 12) ? e.substr(12) : 0 <= (i = e.lastIndexOf("/")) ? e.substr(i + 1) : 0 <= (i = e.lastIndexOf("\\")) ? e.substr(i + 1) : e : "string" == typeof e ? e.replace(/\r/g, "") : e)
            },
            check: function(e) {
                e = this.validationTargetFor(this.clean(e));
                var t, i, n, o = l(e).rules(), r = l.map(o, function(t, e) {
                    return e
                }).length, s = !1, a = this.elementValue(e);
                if ("function" == typeof o.normalizer) {
                    if ("string" != typeof (a = o.normalizer.call(e, a)))
                        throw new TypeError("The normalizer should return a string value.");
                    delete o.normalizer
                }
                for (i in o) {
                    n = {
                        method: i,
                        parameters: o[i]
                    };
                    try {
                        if ("dependency-mismatch" === (t = l.validator.methods[i].call(this, a, e, n.parameters)) && 1 === r) {
                            s = !0;
                            continue
                        }
                        if (s = !1,
                        "pending" === t)
                            return void (this.toHide = this.toHide.not(this.errorsFor(e)));
                        if (!t)
                            return this.formatAndAdd(e, n),
                            !1
                    } catch (t) {
                        throw this.settings.debug && window.console && console.log("Exception occurred when checking element " + e.id + ", check the '" + n.method + "' method.", t),
                        t instanceof TypeError && (t.message += ".  Exception occurred when checking element " + e.id + ", check the '" + n.method + "' method."),
                        t
                    }
                }
                if (!s)
                    return this.objectLength(o) && this.successList.push(e),
                    !0
            },
            customDataMessage: function(t, e) {
                return l(t).data("msg" + e.charAt(0).toUpperCase() + e.substring(1).toLowerCase()) || l(t).data("msg")
            },
            customMessage: function(t, e) {
                var i = this.settings.messages[t];
                return i && (i.constructor === String ? i : i[e])
            },
            findDefined: function() {
                for (var t = 0; t < arguments.length; t++)
                    if (void 0 !== arguments[t])
                        return arguments[t]
            },
            defaultMessage: function(t, e) {
                "string" == typeof e && (e = {
                    method: e
                });
                var i = this.findDefined(this.customMessage(t.name, e.method), this.customDataMessage(t, e.method), !this.settings.ignoreTitle && t.title || void 0, l.validator.messages[e.method], "<strong>Warning: No message defined for " + t.name + "</strong>")
                  , n = /\$?\{(\d+)\}/g;
                return "function" == typeof i ? i = i.call(this, e.parameters, t) : n.test(i) && (i = l.validator.format(i.replace(n, "{$1}"), e.parameters)),
                i
            },
            formatAndAdd: function(t, e) {
                var i = this.defaultMessage(t, e);
                this.errorList.push({
                    message: i,
                    element: t,
                    method: e.method
                }),
                this.errorMap[t.name] = i,
                this.submitted[t.name] = i
            },
            addWrapper: function(t) {
                return this.settings.wrapper && (t = t.add(t.parent(this.settings.wrapper))),
                t
            },
            defaultShowErrors: function() {
                var t, e, i;
                for (t = 0; this.errorList[t]; t++)
                    i = this.errorList[t],
                    this.settings.highlight && this.settings.highlight.call(this, i.element, this.settings.errorClass, this.settings.validClass),
                    this.showLabel(i.element, i.message);
                if (this.errorList.length && (this.toShow = this.toShow.add(this.containers)),
                this.settings.success)
                    for (t = 0; this.successList[t]; t++)
                        this.showLabel(this.successList[t]);
                if (this.settings.unhighlight)
                    for (t = 0,
                    e = this.validElements(); e[t]; t++)
                        this.settings.unhighlight.call(this, e[t], this.settings.errorClass, this.settings.validClass);
                this.toHide = this.toHide.not(this.toShow),
                this.hideErrors(),
                this.addWrapper(this.toShow).show()
            },
            validElements: function() {
                return this.currentElements.not(this.invalidElements())
            },
            invalidElements: function() {
                return l(this.errorList).map(function() {
                    return this.element
                })
            },
            showLabel: function(t, e) {
                var i, n, o, r, s = this.errorsFor(t), a = this.idOrName(t), u = l(t).attr("aria-describedby");
                s.length ? (s.removeClass(this.settings.validClass).addClass(this.settings.errorClass),
                s.html(e)) : (i = s = l("<" + this.settings.errorElement + ">").attr("id", a + "-error").addClass(this.settings.errorClass).html(e || ""),
                this.settings.wrapper && (i = s.hide().show().wrap("<" + this.settings.wrapper + "/>").parent()),
                this.labelContainer.length ? this.labelContainer.append(i) : this.settings.errorPlacement ? this.settings.errorPlacement.call(this, i, l(t)) : i.insertAfter(t),
                s.is("label") ? s.attr("for", a) : 0 === s.parents("label[for='" + this.escapeCssMeta(a) + "']").length && (o = s.attr("id"),
                u ? u.match(new RegExp("\\b" + this.escapeCssMeta(o) + "\\b")) || (u += " " + o) : u = o,
                l(t).attr("aria-describedby", u),
                (n = this.groups[t.name]) && (r = this,
                l.each(r.groups, function(t, e) {
                    e === n && l("[name='" + r.escapeCssMeta(t) + "']", r.currentForm).attr("aria-describedby", s.attr("id"))
                })))),
                !e && this.settings.success && (s.text(""),
                "string" == typeof this.settings.success ? s.addClass(this.settings.success) : this.settings.success(s, t)),
                this.toShow = this.toShow.add(s)
            },
            errorsFor: function(t) {
                var e = this.escapeCssMeta(this.idOrName(t))
                  , i = l(t).attr("aria-describedby")
                  , n = "label[for='" + e + "'], label[for='" + e + "'] *";
                return i && (n = n + ", #" + this.escapeCssMeta(i).replace(/\s+/g, ", #")),
                this.errors().filter(n)
            },
            escapeCssMeta: function(t) {
                return t.replace(/([\\!"#$%&'()*+,./:;<=>?@\[\]^`{|}~])/g, "\\$1")
            },
            idOrName: function(t) {
                return this.groups[t.name] || (this.checkable(t) ? t.name : t.id || t.name)
            },
            validationTargetFor: function(t) {
                return this.checkable(t) && (t = this.findByName(t.name)),
                l(t).not(this.settings.ignore)[0]
            },
            checkable: function(t) {
                return /radio|checkbox/i.test(t.type)
            },
            findByName: function(t) {
                return l(this.currentForm).find("[name='" + this.escapeCssMeta(t) + "']")
            },
            getLength: function(t, e) {
                switch (e.nodeName.toLowerCase()) {
                case "select":
                    return l("option:selected", e).length;
                case "input":
                    if (this.checkable(e))
                        return this.findByName(e.name).filter(":checked").length
                }
                return t.length
            },
            depend: function(t, e) {
                return !this.dependTypes[typeof t] || this.dependTypes[typeof t](t, e)
            },
            dependTypes: {
                boolean: function(t) {
                    return t
                },
                string: function(t, e) {
                    return !!l(t, e.form).length
                },
                function: function(t, e) {
                    return t(e)
                }
            },
            optional: function(t) {
                var e = this.elementValue(t);
                return !l.validator.methods.required.call(this, e, t) && "dependency-mismatch"
            },
            startRequest: function(t) {
                this.pending[t.name] || (this.pendingRequest++,
                l(t).addClass(this.settings.pendingClass),
                this.pending[t.name] = !0)
            },
            stopRequest: function(t, e) {
                this.pendingRequest--,
                this.pendingRequest < 0 && (this.pendingRequest = 0),
                delete this.pending[t.name],
                l(t).removeClass(this.settings.pendingClass),
                e && 0 === this.pendingRequest && this.formSubmitted && this.form() ? (l(this.currentForm).submit(),
                this.formSubmitted = !1) : !e && 0 === this.pendingRequest && this.formSubmitted && (l(this.currentForm).triggerHandler("invalid-form", [this]),
                this.formSubmitted = !1)
            },
            previousValue: function(t, e) {
                return e = "string" == typeof e && e || "remote",
                l.data(t, "previousValue") || l.data(t, "previousValue", {
                    old: null,
                    valid: !0,
                    message: this.defaultMessage(t, {
                        method: e
                    })
                })
            },
            destroy: function() {
                this.resetForm(),
                l(this.currentForm).off(".validate").removeData("validator").find(".validate-equalTo-blur").off(".validate-equalTo").removeClass("validate-equalTo-blur")
            }
        },
        classRuleSettings: {
            required: {
                required: !0
            },
            email: {
                email: !0
            },
            url: {
                url: !0
            },
            date: {
                date: !0
            },
            dateISO: {
                dateISO: !0
            },
            number: {
                number: !0
            },
            digits: {
                digits: !0
            },
            creditcard: {
                creditcard: !0
            }
        },
        addClassRules: function(t, e) {
            t.constructor === String ? this.classRuleSettings[t] = e : l.extend(this.classRuleSettings, t)
        },
        classRules: function(t) {
            var e = {}
              , i = l(t).attr("class");
            return i && l.each(i.split(" "), function() {
                this in l.validator.classRuleSettings && l.extend(e, l.validator.classRuleSettings[this])
            }),
            e
        },
        normalizeAttributeRule: function(t, e, i, n) {
            /min|max|step/.test(i) && (null === e || /number|range|text/.test(e)) && (n = Number(n),
            isNaN(n) && (n = void 0)),
            n || 0 === n ? t[i] = n : e === i && "range" !== e && (t[i] = !0)
        },
        attributeRules: function(t) {
            var e, i, n = {}, o = l(t), r = t.getAttribute("type");
            for (e in l.validator.methods)
                "required" === e ? ("" === (i = t.getAttribute(e)) && (i = !0),
                i = !!i) : i = o.attr(e),
                this.normalizeAttributeRule(n, r, e, i);
            return n.maxlength && /-1|2147483647|524288/.test(n.maxlength) && delete n.maxlength,
            n
        },
        dataRules: function(t) {
            var e, i, n = {}, o = l(t), r = t.getAttribute("type");
            for (e in l.validator.methods)
                i = o.data("rule" + e.charAt(0).toUpperCase() + e.substring(1).toLowerCase()),
                this.normalizeAttributeRule(n, r, e, i);
            return n
        },
        staticRules: function(t) {
            var e = {}
              , i = l.data(t.form, "validator");
            return i.settings.rules && (e = l.validator.normalizeRule(i.settings.rules[t.name]) || {}),
            e
        },
        normalizeRules: function(n, o) {
            return l.each(n, function(t, e) {
                if (!1 !== e) {
                    if (e.param || e.depends) {
                        var i = !0;
                        switch (typeof e.depends) {
                        case "string":
                            i = !!l(e.depends, o.form).length;
                            break;
                        case "function":
                            i = e.depends.call(o, o)
                        }
                        i ? n[t] = void 0 === e.param || e.param : (l.data(o.form, "validator").resetElements(l(o)),
                        delete n[t])
                    }
                } else
                    delete n[t]
            }),
            l.each(n, function(t, e) {
                n[t] = l.isFunction(e) && "normalizer" !== t ? e(o) : e
            }),
            l.each(["minlength", "maxlength"], function() {
                n[this] && (n[this] = Number(n[this]))
            }),
            l.each(["rangelength", "range"], function() {
                var t;
                n[this] && (l.isArray(n[this]) ? n[this] = [Number(n[this][0]), Number(n[this][1])] : "string" == typeof n[this] && (t = n[this].replace(/[\[\]]/g, "").split(/[\s,]+/),
                n[this] = [Number(t[0]), Number(t[1])]))
            }),
            l.validator.autoCreateRanges && (null != n.min && null != n.max && (n.range = [n.min, n.max],
            delete n.min,
            delete n.max),
            null != n.minlength && null != n.maxlength && (n.rangelength = [n.minlength, n.maxlength],
            delete n.minlength,
            delete n.maxlength)),
            n
        },
        normalizeRule: function(t) {
            if ("string" == typeof t) {
                var e = {};
                l.each(t.split(/\s/), function() {
                    e[this] = !0
                }),
                t = e
            }
            return t
        },
        addMethod: function(t, e, i) {
            l.validator.methods[t] = e,
            l.validator.messages[t] = void 0 !== i ? i : l.validator.messages[t],
            e.length < 3 && l.validator.addClassRules(t, l.validator.normalizeRule(t))
        },
        methods: {
            required: function(t, e, i) {
                if (!this.depend(i, e))
                    return "dependency-mismatch";
                if ("select" === e.nodeName.toLowerCase()) {
                    var n = l(e).val();
                    return n && 0 < n.length
                }
                return this.checkable(e) ? 0 < this.getLength(t, e) : 0 < t.length
            },
            email: function(t, e) {
                return this.optional(e) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(t)
            },
            url: function(t, e) {
                return this.optional(e) || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(t)
            },
            date: function(t, e) {
                return this.optional(e) || !/Invalid|NaN/.test(new Date(t).toString())
            },
            dateISO: function(t, e) {
                return this.optional(e) || /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(t)
            },
            number: function(t, e) {
                return this.optional(e) || /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(t)
            },
            digits: function(t, e) {
                return this.optional(e) || /^\d+$/.test(t)
            },
            minlength: function(t, e, i) {
                var n = l.isArray(t) ? t.length : this.getLength(t, e);
                return this.optional(e) || i <= n
            },
            maxlength: function(t, e, i) {
                var n = l.isArray(t) ? t.length : this.getLength(t, e);
                return this.optional(e) || n <= i
            },
            rangelength: function(t, e, i) {
                var n = l.isArray(t) ? t.length : this.getLength(t, e);
                return this.optional(e) || n >= i[0] && n <= i[1]
            },
            min: function(t, e, i) {
                return this.optional(e) || i <= t
            },
            max: function(t, e, i) {
                return this.optional(e) || t <= i
            },
            range: function(t, e, i) {
                return this.optional(e) || t >= i[0] && t <= i[1]
            },
            step: function(t, e, i) {
                var n, o = l(e).attr("type"), r = "Step attribute on input type " + o + " is not supported.", s = new RegExp("\\b" + o + "\\b"), a = function(t) {
                    var e = ("" + t).match(/(?:\.(\d+))?$/);
                    return e && e[1] ? e[1].length : 0
                }, u = function(t) {
                    return Math.round(t * Math.pow(10, n))
                }, h = !0;
                if (o && !s.test(["text", "number", "range"].join()))
                    throw new Error(r);
                return n = a(i),
                (a(t) > n || u(t) % u(i) != 0) && (h = !1),
                this.optional(e) || h
            },
            equalTo: function(t, e, i) {
                var n = l(i);
                return this.settings.onfocusout && n.not(".validate-equalTo-blur").length && n.addClass("validate-equalTo-blur").on("blur.validate-equalTo", function() {
                    l(e).valid()
                }),
                t === n.val()
            },
            remote: function(r, s, t, a) {
                if (this.optional(s))
                    return "dependency-mismatch";
                a = "string" == typeof a && a || "remote";
                var u, e, i, h = this.previousValue(s, a);
                return this.settings.messages[s.name] || (this.settings.messages[s.name] = {}),
                h.originalMessage = h.originalMessage || this.settings.messages[s.name][a],
                this.settings.messages[s.name][a] = h.message,
                t = "string" == typeof t && {
                    url: t
                } || t,
                i = l.param(l.extend({
                    data: r
                }, t.data)),
                h.old === i ? h.valid : (h.old = i,
                (u = this).startRequest(s),
                (e = {})[s.name] = r,
                l.ajax(l.extend(!0, {
                    mode: "abort",
                    port: "validate" + s.name,
                    dataType: "json",
                    data: e,
                    context: u.currentForm,
                    success: function(t) {
                        var e, i, n, o = !0 === t || "true" === t;
                        u.settings.messages[s.name][a] = h.originalMessage,
                        o ? (n = u.formSubmitted,
                        u.resetInternals(),
                        u.toHide = u.errorsFor(s),
                        u.formSubmitted = n,
                        u.successList.push(s),
                        u.invalid[s.name] = !1,
                        u.showErrors()) : (e = {},
                        i = t || u.defaultMessage(s, {
                            method: a,
                            parameters: r
                        }),
                        e[s.name] = h.message = i,
                        u.invalid[s.name] = !0,
                        u.showErrors(e)),
                        h.valid = o,
                        u.stopRequest(s, o)
                    }
                }, t)),
                "pending")
            }
        }
    });
    var n, o = {};
    l.ajaxPrefilter ? l.ajaxPrefilter(function(t, e, i) {
        var n = t.port;
        "abort" === t.mode && (o[n] && o[n].abort(),
        o[n] = i)
    }) : (n = l.ajax,
    l.ajax = function(t) {
        var e = ("mode"in t ? t : l.ajaxSettings).mode
          , i = ("port"in t ? t : l.ajaxSettings).port;
        return "abort" === e ? (o[i] && o[i].abort(),
        o[i] = n.apply(this, arguments),
        o[i]) : n.apply(this, arguments)
    }
    )
}),
function() {
    "use strict";
    function a(o, t) {
        var e;
        if (t = t || {},
        this.trackingClick = !1,
        this.trackingClickStart = 0,
        this.targetElement = null,
        this.touchStartX = 0,
        this.touchStartY = 0,
        this.lastTouchIdentifier = 0,
        this.touchBoundary = t.touchBoundary || 10,
        this.layer = o,
        this.tapDelay = t.tapDelay || 200,
        this.tapTimeout = t.tapTimeout || 700,
        !a.notNeeded(o)) {
            for (var i = ["onMouse", "onClick", "onTouchStart", "onTouchMove", "onTouchEnd", "onTouchCancel"], n = 0, r = i.length; n < r; n++)
                this[i[n]] = s(this[i[n]], this);
            u && (o.addEventListener("mouseover", this.onMouse, !0),
            o.addEventListener("mousedown", this.onMouse, !0),
            o.addEventListener("mouseup", this.onMouse, !0)),
            o.addEventListener("click", this.onClick, !0),
            o.addEventListener("touchstart", this.onTouchStart, !1),
            o.addEventListener("touchmove", this.onTouchMove, !1),
            o.addEventListener("touchend", this.onTouchEnd, !1),
            o.addEventListener("touchcancel", this.onTouchCancel, !1),
            Event.prototype.stopImmediatePropagation || (o.removeEventListener = function(t, e, i) {
                var n = Node.prototype.removeEventListener;
                "click" === t ? n.call(o, t, e.hijacked || e, i) : n.call(o, t, e, i)
            }
            ,
            o.addEventListener = function(t, e, i) {
                var n = Node.prototype.addEventListener;
                "click" === t ? n.call(o, t, e.hijacked || (e.hijacked = function(t) {
                    t.propagationStopped || e(t)
                }
                ), i) : n.call(o, t, e, i)
            }
            ),
            "function" == typeof o.onclick && (e = o.onclick,
            o.addEventListener("click", function(t) {
                e(t)
            }, !1),
            o.onclick = null)
        }
        function s(t, e) {
            return function() {
                return t.apply(e, arguments)
            }
        }
    }
    var t = 0 <= navigator.userAgent.indexOf("Windows Phone")
      , u = 0 < navigator.userAgent.indexOf("Android") && !t
      , h = /iP(ad|hone|od)/.test(navigator.userAgent) && !t
      , l = h && /OS 4_\d(_\d)?/.test(navigator.userAgent)
      , c = h && /OS [6-7]_\d/.test(navigator.userAgent)
      , o = 0 < navigator.userAgent.indexOf("BB10");
    a.prototype.needsClick = function(t) {
        switch (t.nodeName.toLowerCase()) {
        case "button":
        case "select":
        case "textarea":
            if (t.disabled)
                return !0;
            break;
        case "input":
            if (h && "file" === t.type || t.disabled)
                return !0;
            break;
        case "label":
        case "iframe":
        case "video":
            return !0
        }
        return /\bneedsclick\b/.test(t.className)
    }
    ,
    a.prototype.needsFocus = function(t) {
        switch (t.nodeName.toLowerCase()) {
        case "textarea":
            return !0;
        case "select":
            return !u;
        case "input":
            switch (t.type) {
            case "button":
            case "checkbox":
            case "file":
            case "image":
            case "radio":
            case "submit":
                return !1
            }
            return !t.disabled && !t.readOnly;
        default:
            return /\bneedsfocus\b/.test(t.className)
        }
    }
    ,
    a.prototype.sendClick = function(t, e) {
        var i, n;
        document.activeElement && document.activeElement !== t && document.activeElement.blur(),
        n = e.changedTouches[0],
        (i = document.createEvent("MouseEvents")).initMouseEvent(this.determineEventType(t), !0, !0, window, 1, n.screenX, n.screenY, n.clientX, n.clientY, !1, !1, !1, !1, 0, null),
        i.forwardedTouchEvent = !0,
        t.dispatchEvent(i)
    }
    ,
    a.prototype.determineEventType = function(t) {
        return u && "select" === t.tagName.toLowerCase() ? "mousedown" : "click"
    }
    ,
    a.prototype.focus = function(t) {
        var e;
        h && t.setSelectionRange && 0 !== t.type.indexOf("date") && "time" !== t.type && "month" !== t.type ? (e = t.value.length,
        t.setSelectionRange(e, e)) : t.focus()
    }
    ,
    a.prototype.updateScrollParent = function(t) {
        var e, i;
        if (!(e = t.fastClickScrollParent) || !e.contains(t)) {
            i = t;
            do {
                if (i.scrollHeight > i.offsetHeight) {
                    e = i,
                    t.fastClickScrollParent = i;
                    break
                }
                i = i.parentElement
            } while (i)
        }
        e && (e.fastClickLastScrollTop = e.scrollTop)
    }
    ,
    a.prototype.getTargetElementFromEventTarget = function(t) {
        return t.nodeType === Node.TEXT_NODE ? t.parentNode : t
    }
    ,
    a.prototype.onTouchStart = function(t) {
        var e, i, n;
        if (1 < t.targetTouches.length)
            return !0;
        if (e = this.getTargetElementFromEventTarget(t.target),
        i = t.targetTouches[0],
        h) {
            if ((n = window.getSelection()).rangeCount && !n.isCollapsed)
                return !0;
            if (!l) {
                if (i.identifier && i.identifier === this.lastTouchIdentifier)
                    return t.preventDefault(),
                    !1;
                this.lastTouchIdentifier = i.identifier,
                this.updateScrollParent(e)
            }
        }
        return this.trackingClick = !0,
        this.trackingClickStart = t.timeStamp,
        this.targetElement = e,
        this.touchStartX = i.pageX,
        this.touchStartY = i.pageY,
        t.timeStamp - this.lastClickTime < this.tapDelay && t.preventDefault(),
        !0
    }
    ,
    a.prototype.touchHasMoved = function(t) {
        var e = t.changedTouches[0]
          , i = this.touchBoundary;
        return Math.abs(e.pageX - this.touchStartX) > i || Math.abs(e.pageY - this.touchStartY) > i
    }
    ,
    a.prototype.onTouchMove = function(t) {
        return this.trackingClick && (this.targetElement !== this.getTargetElementFromEventTarget(t.target) || this.touchHasMoved(t)) && (this.trackingClick = !1,
        this.targetElement = null),
        !0
    }
    ,
    a.prototype.findControl = function(t) {
        return void 0 !== t.control ? t.control : t.htmlFor ? document.getElementById(t.htmlFor) : t.querySelector("button, input:not([type=hidden]), keygen, meter, output, progress, select, textarea")
    }
    ,
    a.prototype.onTouchEnd = function(t) {
        var e, i, n, o, r, s = this.targetElement;
        if (!this.trackingClick)
            return !0;
        if (t.timeStamp - this.lastClickTime < this.tapDelay)
            return this.cancelNextClick = !0;
        if (t.timeStamp - this.trackingClickStart > this.tapTimeout)
            return !0;
        if (this.cancelNextClick = !1,
        this.lastClickTime = t.timeStamp,
        i = this.trackingClickStart,
        this.trackingClick = !1,
        this.trackingClickStart = 0,
        c && (r = t.changedTouches[0],
        (s = document.elementFromPoint(r.pageX - window.pageXOffset, r.pageY - window.pageYOffset) || s).fastClickScrollParent = this.targetElement.fastClickScrollParent),
        "label" === (n = s.tagName.toLowerCase())) {
            if (e = this.findControl(s)) {
                if (this.focus(s),
                u)
                    return !1;
                s = e
            }
        } else if (this.needsFocus(s))
            return 100 < t.timeStamp - i || h && window.top !== window && "input" === n ? this.targetElement = null : (this.focus(s),
            this.sendClick(s, t),
            h && "select" === n || (this.targetElement = null,
            t.preventDefault())),
            !1;
        return !(!h || l || !(o = s.fastClickScrollParent) || o.fastClickLastScrollTop === o.scrollTop) || (this.needsClick(s) || (t.preventDefault(),
        this.sendClick(s, t)),
        !1)
    }
    ,
    a.prototype.onTouchCancel = function() {
        this.trackingClick = !1,
        this.targetElement = null
    }
    ,
    a.prototype.onMouse = function(t) {
        return !this.targetElement || (!!t.forwardedTouchEvent || (!t.cancelable || (!(!this.needsClick(this.targetElement) || this.cancelNextClick) || (t.stopImmediatePropagation ? t.stopImmediatePropagation() : t.propagationStopped = !0,
        t.stopPropagation(),
        t.preventDefault(),
        !1))))
    }
    ,
    a.prototype.onClick = function(t) {
        var e;
        return this.trackingClick ? (this.targetElement = null,
        !(this.trackingClick = !1)) : "submit" === t.target.type && 0 === t.detail || ((e = this.onMouse(t)) || (this.targetElement = null),
        e)
    }
    ,
    a.prototype.destroy = function() {
        var t = this.layer;
        u && (t.removeEventListener("mouseover", this.onMouse, !0),
        t.removeEventListener("mousedown", this.onMouse, !0),
        t.removeEventListener("mouseup", this.onMouse, !0)),
        t.removeEventListener("click", this.onClick, !0),
        t.removeEventListener("touchstart", this.onTouchStart, !1),
        t.removeEventListener("touchmove", this.onTouchMove, !1),
        t.removeEventListener("touchend", this.onTouchEnd, !1),
        t.removeEventListener("touchcancel", this.onTouchCancel, !1)
    }
    ,
    a.notNeeded = function(t) {
        var e, i, n;
        if (void 0 === window.ontouchstart)
            return !0;
        if (i = +(/Chrome\/([0-9]+)/.exec(navigator.userAgent) || [, 0])[1]) {
            if (!u)
                return !0;
            if (e = document.querySelector("meta[name=viewport]")) {
                if (-1 !== e.content.indexOf("user-scalable=no"))
                    return !0;
                if (31 < i && document.documentElement.scrollWidth <= window.outerWidth)
                    return !0
            }
        }
        if (o && 10 <= (n = navigator.userAgent.match(/Version\/([0-9]*)\.([0-9]*)/))[1] && 3 <= n[2] && (e = document.querySelector("meta[name=viewport]"))) {
            if (-1 !== e.content.indexOf("user-scalable=no"))
                return !0;
            if (document.documentElement.scrollWidth <= window.outerWidth)
                return !0
        }
        return "none" === t.style.msTouchAction || "manipulation" === t.style.touchAction || (!!(27 <= +(/Firefox\/([0-9]+)/.exec(navigator.userAgent) || [, 0])[1] && (e = document.querySelector("meta[name=viewport]")) && (-1 !== e.content.indexOf("user-scalable=no") || document.documentElement.scrollWidth <= window.outerWidth)) || ("none" === t.style.touchAction || "manipulation" === t.style.touchAction))
    }
    ,
    a.attach = function(t, e) {
        return new a(t,e)
    }
    ,
    "function" == typeof define && "object" == typeof define.amd && define.amd ? define(function() {
        return a
    }) : "undefined" != typeof module && module.exports ? (module.exports = a.attach,
    module.exports.FastClick = a) : window.FastClick = a
}(),
function(s, t) {
    "use strict";
    if ("function" != typeof s.createEvent)
        return;
    var a, u, h, l, c, d, f, p, e = function(t) {
        var e = t.toLowerCase()
          , i = "MS" + t;
        return navigator.msPointerEnabled ? i : e
    }, m = {
        useJquery: !t.IGNORE_JQUERY && "undefined" != typeof jQuery,
        swipeThreshold: t.SWIPE_THRESHOLD || 100,
        tapThreshold: t.TAP_THRESHOLD || 150,
        dbltapThreshold: t.DBL_TAP_THRESHOLD || 200,
        longtapThreshold: t.LONG_TAP_THRESHOLD || 1e3,
        tapPrecision: t.TAP_PRECISION / 2 || 30,
        justTouchEvents: t.JUST_ON_TOUCH_DEVICES
    }, g = !1, i = e("PointerDown") + " touchstart", n = e("PointerUp") + " touchend", o = e("PointerMove") + " touchmove", r = function(t, e, i) {
        for (var n = e.split(" "), o = n.length; o--; )
            t.addEventListener(n[o], i, !1)
    }, v = function(t) {
        return t.targetTouches ? t.targetTouches[0] : t
    }, y = function() {
        return (new Date).getTime()
    }, b = function(t, e, i, n) {
        var o = s.createEvent("Event");
        if (o.originalEvent = i,
        (n = n || {}).x = a,
        n.y = u,
        n.distance = n.distance,
        m.useJquery && (o = jQuery.Event(e, {
            originalEvent: i
        }),
        jQuery(t).trigger(o, n)),
        o.initEvent) {
            for (var r in n)
                o[r] = n[r];
            o.initEvent(e, !0, !0),
            t.dispatchEvent(o)
        }
        for (; t; )
            t["on" + e] && t["on" + e](o),
            t = t.parentNode
    }, x = 0;
    r(s, i + (m.justTouchEvents ? "" : " mousedown"), function(t) {
        if ("mousedown" !== t.type && (g = !0),
        "mousedown" !== t.type || !g) {
            var e = v(t);
            h = a = e.pageX,
            l = u = e.pageY,
            p = setTimeout(function() {
                b(t.target, "longtap", t),
                d = t.target
            }, m.longtapThreshold),
            c = y(),
            x++
        }
    }),
    r(s, n + (m.justTouchEvents ? "" : " mouseup"), function(t) {
        if ("mouseup" === t.type && g)
            g = !1;
        else {
            var e = []
              , i = y()
              , n = l - u
              , o = h - a;
            if (clearTimeout(f),
            clearTimeout(p),
            o <= -m.swipeThreshold && e.push("swiperight"),
            o >= m.swipeThreshold && e.push("swipeleft"),
            n <= -m.swipeThreshold && e.push("swipedown"),
            n >= m.swipeThreshold && e.push("swipeup"),
            e.length) {
                for (var r = 0; r < e.length; r++) {
                    var s = e[r];
                    b(t.target, s, t, {
                        distance: {
                            x: Math.abs(o),
                            y: Math.abs(n)
                        }
                    })
                }
                x = 0
            } else
                h >= a - m.tapPrecision && h <= a + m.tapPrecision && l >= u - m.tapPrecision && l <= u + m.tapPrecision && 0 <= c + m.tapThreshold - i && (b(t.target, 2 <= x && d === t.target ? "dbltap" : "tap", t),
                d = t.target),
                f = setTimeout(function() {
                    x = 0
                }, m.dbltapThreshold)
        }
    }),
    r(s, o + (m.justTouchEvents ? "" : " mousemove"), function(t) {
        if ("mousemove" !== t.type || !g) {
            var e = v(t);
            a = e.pageX,
            u = e.pageY
        }
    }),
    t.tocca = function(t) {
        for (var e in t)
            m[e] = t[e];
        return m
    }
}(document, window),
function(i, v) {
    v.fn.extend({
        scrollspy: function(p, t) {
            if (r(p)) {
                var e = t;
                t = p,
                p = e
            }
            s(p = v.extend({}, n, p), n, "container", o);
            var m = v(p.container);
            if (0 === m.length)
                return this;
            if (s(p, n, "namespace", r),
            r(t) && "DESTROY" === t.toUpperCase())
                return m.off("scroll." + p.namespace),
                this;
            s(p, n, "buffer", v.isNumeric),
            s(p, n, "max", v.isNumeric),
            s(p, n, "min", v.isNumeric),
            s(p, n, "onEnter", v.isFunction),
            s(p, n, "onLeave", v.isFunction),
            s(p, n, "onLeaveTop", v.isFunction),
            s(p, n, "onLeaveBottom", v.isFunction),
            s(p, n, "onTick", v.isFunction),
            v.isFunction(p.max) && (p.max = p.max()),
            v.isFunction(p.min) && (p.min = p.min());
            var g = "VERTICAL" === i.String(p.mode).toUpperCase();
            return this.each(function() {
                var h = this
                  , l = v(h)
                  , c = 0
                  , d = !1
                  , f = 0;
                m.on("scroll." + p.namespace, function() {
                    var t = v(this)
                      , e = {
                        top: t.scrollTop(),
                        left: t.scrollLeft()
                    }
                      , i = m.height()
                      , n = p.max
                      , o = p.min
                      , r = g ? e.top + p.buffer : e.left + p.buffer;
                    if (0 === n && (n = g ? i : m.outerWidth() + l.outerWidth()),
                    o <= r && r <= n)
                        d || (d = !0,
                        c++,
                        l.trigger("scrollEnter", {
                            position: e
                        }),
                        null !== p.onEnter && p.onEnter(h, e)),
                        l.trigger("scrollTick", {
                            position: e,
                            inside: d,
                            enters: c,
                            leaves: f
                        }),
                        null !== p.onTick && p.onTick(h, e, d, c, f);
                    else if (d)
                        d = !1,
                        f++,
                        l.trigger("scrollLeave", {
                            position: e,
                            leaves: f
                        }),
                        null !== p.onLeave && p.onLeave(h, e),
                        r <= o ? (l.trigger("scrollLeaveTop", {
                            position: e,
                            leaves: f
                        }),
                        null !== p.onLeaveTop && p.onLeaveTop(h, e)) : n <= r && (l.trigger("scrollLeaveBottom", {
                            position: e,
                            leaves: f
                        }),
                        null !== p.onLeaveBottom && p.onLeaveBottom(h, e));
                    else {
                        var s = m.scrollTop()
                          , a = l.height()
                          , u = l.offset().top;
                        u < i + s && s - a < u && (l.trigger("scrollView", {
                            position: e
                        }),
                        null !== p.onView && p.onView(h, e))
                    }
                })
            })
        }
    });
    var n = {
        buffer: 0,
        container: i,
        max: 0,
        min: 0,
        mode: "vertical",
        namespace: "scrollspy",
        onEnter: null,
        onLeave: null,
        onLeaveTop: null,
        onLeaveBottom: null,
        onTick: null,
        onView: null
    };
    function o(t) {
        return "object" === v.type(t)
    }
    function r(t) {
        return "string" === v.type(t) && 0 < v.trim(t).length
    }
    function s(t, e, i, n) {
        n(t[i]) || (t[i] = e[i])
    }
}(window, window.jQuery),
function(t) {
    "function" == typeof define && define.amd ? define(["jquery"], t) : "object" == typeof exports ? module.exports = t : t(jQuery)
}(function(d) {
    var f, p, t = ["wheel", "mousewheel", "DOMMouseScroll", "MozMousePixelScroll"], e = "onwheel"in document || 9 <= document.documentMode ? ["wheel"] : ["mousewheel", "DomMouseScroll", "MozMousePixelScroll"], m = Array.prototype.slice;
    if (d.event.fixHooks)
        for (var i = t.length; i; )
            d.event.fixHooks[t[--i]] = d.event.mouseHooks;
    var g = d.event.special.mousewheel = {
        version: "3.1.12",
        setup: function() {
            if (this.addEventListener)
                for (var t = e.length; t; )
                    this.addEventListener(e[--t], n, !1);
            else
                this.onmousewheel = n;
            d.data(this, "mousewheel-line-height", g.getLineHeight(this)),
            d.data(this, "mousewheel-page-height", g.getPageHeight(this))
        },
        teardown: function() {
            if (this.removeEventListener)
                for (var t = e.length; t; )
                    this.removeEventListener(e[--t], n, !1);
            else
                this.onmousewheel = null;
            d.removeData(this, "mousewheel-line-height"),
            d.removeData(this, "mousewheel-page-height")
        },
        getLineHeight: function(t) {
            var e = d(t)
              , i = e["offsetParent"in d.fn ? "offsetParent" : "parent"]();
            return i.length || (i = d("body")),
            parseInt(i.css("fontSize"), 10) || parseInt(e.css("fontSize"), 10) || 16
        },
        getPageHeight: function(t) {
            return d(t).height()
        },
        settings: {
            adjustOldDeltas: !0,
            normalizeOffset: !0
        }
    };
    function n(t) {
        var e, i = t || window.event, n = m.call(arguments, 1), o = 0, r = 0, s = 0, a = 0, u = 0;
        if ((t = d.event.fix(i)).type = "mousewheel",
        "detail"in i && (s = -1 * i.detail),
        "wheelDelta"in i && (s = i.wheelDelta),
        "wheelDeltaY"in i && (s = i.wheelDeltaY),
        "wheelDeltaX"in i && (r = -1 * i.wheelDeltaX),
        "axis"in i && i.axis === i.HORIZONTAL_AXIS && (r = -1 * s,
        s = 0),
        o = 0 === s ? r : s,
        "deltaY"in i && (o = s = -1 * i.deltaY),
        "deltaX"in i && (r = i.deltaX,
        0 === s && (o = -1 * r)),
        0 !== s || 0 !== r) {
            if (1 === i.deltaMode) {
                var h = d.data(this, "mousewheel-line-height");
                o *= h,
                s *= h,
                r *= h
            } else if (2 === i.deltaMode) {
                var l = d.data(this, "mousewheel-page-height");
                o *= l,
                s *= l,
                r *= l
            }
            if (e = Math.max(Math.abs(s), Math.abs(r)),
            (!p || e < p) && y(i, p = e) && (p /= 40),
            y(i, e) && (o /= 40,
            r /= 40,
            s /= 40),
            o = Math[1 <= o ? "floor" : "ceil"](o / p),
            r = Math[1 <= r ? "floor" : "ceil"](r / p),
            s = Math[1 <= s ? "floor" : "ceil"](s / p),
            g.settings.normalizeOffset && this.getBoundingClientRect) {
                var c = this.getBoundingClientRect();
                a = t.clientX - c.left,
                u = t.clientY - c.top
            }
            return t.deltaX = r,
            t.deltaY = s,
            t.deltaFactor = p,
            t.offsetX = a,
            t.offsetY = u,
            t.deltaMode = 0,
            n.unshift(t, o, r, s),
            f && clearTimeout(f),
            f = setTimeout(v, 200),
            (d.event.dispatch || d.event.handle).apply(this, n)
        }
    }
    function v() {
        p = null
    }
    function y(t, e) {
        return g.settings.adjustOldDeltas && "mousewheel" === t.type && e % 120 == 0
    }
    d.fn.extend({
        mousewheel: function(t) {
            return t ? this.bind("mousewheel", t) : this.trigger("mousewheel")
        },
        unmousewheel: function(t) {
            return this.unbind("mousewheel", t)
        }
    })
}),
function(t) {
    "use strict";
    "function" == typeof define && define.amd ? define(["jquery"], t) : "undefined" != typeof module && module.exports ? module.exports = t(require("jquery")) : t(jQuery)
}(function(s) {
    "use strict";
    function v(t) {
        return !t.nodeName || -1 !== s.inArray(t.nodeName.toLowerCase(), ["iframe", "#document", "html", "body"])
    }
    function e(t) {
        return s.isFunction(t) || s.isPlainObject(t) ? t : {
            top: t,
            left: t
        }
    }
    var y = s.scrollTo = function(t, e, i) {
        return s(window).scrollTo(t, e, i)
    }
    ;
    return y.defaults = {
        axis: "xy",
        duration: 0,
        limit: !0
    },
    s.fn.scrollTo = function(t, i, m) {
        "object" == typeof i && (m = i,
        i = 0),
        "function" == typeof m && (m = {
            onAfter: m
        }),
        "max" === t && (t = 9e9),
        m = s.extend({}, y.defaults, m),
        i = i || m.duration;
        var g = m.queue && 1 < m.axis.length;
        return g && (i /= 2),
        m.offset = e(m.offset),
        m.over = e(m.over),
        this.each(function() {
            function a(t) {
                var e = s.extend({}, m, {
                    queue: !0,
                    duration: i,
                    complete: t && function() {
                        t.call(l, d, m)
                    }
                });
                c.animate(f, e)
            }
            if (null !== t) {
                var u, h = v(this), l = h ? this.contentWindow || window : this, c = s(l), d = t, f = {};
                switch (typeof d) {
                case "number":
                case "string":
                    if (/^([+-]=?)?\d+(\.\d+)?(px|%)?$/.test(d)) {
                        d = e(d);
                        break
                    }
                    d = h ? s(d) : s(d, l);
                case "object":
                    if (0 === d.length)
                        return;
                    (d.is || d.style) && (u = (d = s(d)).offset())
                }
                var p = s.isFunction(m.offset) && m.offset(l, d) || m.offset;
                s.each(m.axis.split(""), function(t, e) {
                    var i = "x" === e ? "Left" : "Top"
                      , n = i.toLowerCase()
                      , o = "scroll" + i
                      , r = c[o]()
                      , s = y.max(l, e);
                    u ? (f[o] = u[n] + (h ? 0 : r - c.offset()[n]),
                    m.margin && (f[o] -= parseInt(d.css("margin" + i), 10) || 0,
                    f[o] -= parseInt(d.css("border" + i + "Width"), 10) || 0),
                    f[o] += p[n] || 0,
                    m.over[n] && (f[o] += d["x" === e ? "width" : "height"]() * m.over[n])) : (i = d[n],
                    f[o] = i.slice && "%" === i.slice(-1) ? parseFloat(i) / 100 * s : i),
                    m.limit && /^\d+$/.test(f[o]) && (f[o] = f[o] <= 0 ? 0 : Math.min(f[o], s)),
                    !t && 1 < m.axis.length && (r === f[o] ? f = {} : g && (a(m.onAfterFirst),
                    f = {}))
                }),
                a(m.onAfter)
            }
        })
    }
    ,
    y.max = function(t, e) {
        var i = "scroll" + (n = "x" === e ? "Width" : "Height");
        if (!v(t))
            return t[i] - s(t)[n.toLowerCase()]();
        var n = "client" + n
          , o = (r = t.ownerDocument || t.document).documentElement
          , r = r.body;
        return Math.max(o[i], r[i]) - Math.min(o[n], r[n])
    }
    ,
    s.Tween.propHooks.scrollLeft = s.Tween.propHooks.scrollTop = {
        get: function(t) {
            return s(t.elem)[t.prop]()
        },
        set: function(t) {
            var e = this.get(t);
            if (t.options.interrupt && t._last && t._last !== e)
                return s(t.elem).stop();
            var i = Math.round(t.now);
            e !== i && (s(t.elem)[t.prop](i),
            t._last = this.get(t))
        }
    },
    y
}),
function(T) {
    T.isScrollToFixed = function(t) {
        return !!T(t).data("ScrollToFixed")
    }
    ,
    T.ScrollToFixed = function(t, e) {
        var r = this;
        r.$el = T(t),
        r.el = t,
        r.$el.data("ScrollToFixed", r);
        var s, a, i, n, u = !1, h = r.$el, l = 0, c = 0, d = -1, f = -1, o = null;
        function p() {
            var t = r.options.limit;
            return t ? "function" == typeof t ? t.apply(h) : t : 0
        }
        function m() {
            return "fixed" === s
        }
        function g() {
            return "absolute" === s
        }
        function v() {
            return !(m() || g())
        }
        function y() {
            if (!m()) {
                var t = h[0].getBoundingClientRect();
                o.css({
                    display: h.css("display"),
                    width: t.width,
                    height: t.height,
                    float: h.css("float")
                }),
                cssOptions = {
                    "z-index": r.options.zIndex,
                    position: "fixed",
                    top: -1 == r.options.bottom ? E() : "",
                    bottom: -1 == r.options.bottom ? "" : r.options.bottom,
                    "margin-left": "0px"
                },
                r.options.dontSetWidth || (cssOptions.width = h.css("width")),
                h.css(cssOptions),
                h.addClass(r.options.baseClassName),
                r.options.className && h.addClass(r.options.className),
                s = "fixed"
            }
        }
        function b() {
            var t = p()
              , e = c;
            r.options.removeOffsets && (e = "",
            t -= l),
            cssOptions = {
                position: "absolute",
                top: t,
                left: e,
                "margin-left": "0px",
                bottom: ""
            },
            r.options.dontSetWidth || (cssOptions.width = h.css("width")),
            h.css(cssOptions),
            s = "absolute"
        }
        function x() {
            v() || (f = -1,
            o.css("display", "none"),
            h.css({
                "z-index": n,
                width: "",
                position: a,
                left: "",
                top: i,
                "margin-left": ""
            }),
            h.removeClass("scroll-to-fixed-fixed"),
            r.options.className && h.removeClass(r.options.className),
            s = null)
        }
        function w(t) {
            t != f && (h.css("left", c - t),
            f = t)
        }
        function E() {
            var t = r.options.marginTop;
            return t ? "function" == typeof t ? t.apply(h) : t : 0
        }
        function S() {
            if (T.isScrollToFixed(h) && !h.is(":hidden")) {
                var t = u
                  , e = v();
                u ? v() && (l = h.offset().top,
                c = h.offset().left) : (h.trigger("preUnfixed.ScrollToFixed"),
                x(),
                h.trigger("unfixed.ScrollToFixed"),
                f = -1,
                l = h.offset().top,
                c = h.offset().left,
                r.options.offsets && (c += h.offset().left - h.position().left),
                -1 == d && (d = c),
                s = h.css("position"),
                u = !0,
                -1 != r.options.bottom && (h.trigger("preFixed.ScrollToFixed"),
                y(),
                h.trigger("fixed.ScrollToFixed")));
                var i = T(window).scrollLeft()
                  , n = T(window).scrollTop()
                  , o = p();
                r.options.minWidth && T(window).width() < r.options.minWidth ? v() && t || (C(),
                h.trigger("preUnfixed.ScrollToFixed"),
                x(),
                h.trigger("unfixed.ScrollToFixed")) : r.options.maxWidth && T(window).width() > r.options.maxWidth ? v() && t || (C(),
                h.trigger("preUnfixed.ScrollToFixed"),
                x(),
                h.trigger("unfixed.ScrollToFixed")) : -1 == r.options.bottom ? 0 < o && n >= o - E() ? e || g() && t || (C(),
                h.trigger("preAbsolute.ScrollToFixed"),
                b(),
                h.trigger("unfixed.ScrollToFixed")) : n >= l - E() ? (m() && t || (C(),
                h.trigger("preFixed.ScrollToFixed"),
                y(),
                f = -1,
                h.trigger("fixed.ScrollToFixed")),
                w(i)) : v() && t || (C(),
                h.trigger("preUnfixed.ScrollToFixed"),
                x(),
                h.trigger("unfixed.ScrollToFixed")) : 0 < o ? n + T(window).height() - h.outerHeight(!0) >= o - (E() || -(r.options.bottom ? r.options.bottom : 0)) ? m() && (C(),
                h.trigger("preUnfixed.ScrollToFixed"),
                "absolute" === a ? b() : x(),
                h.trigger("unfixed.ScrollToFixed")) : (m() || (C(),
                h.trigger("preFixed.ScrollToFixed"),
                y()),
                w(i),
                h.trigger("fixed.ScrollToFixed")) : w(i)
            }
        }
        function C() {
            var t = h.css("position");
            "absolute" == t ? h.trigger("postAbsolute.ScrollToFixed") : "fixed" == t ? h.trigger("postFixed.ScrollToFixed") : h.trigger("postUnfixed.ScrollToFixed")
        }
        var z = function(t) {
            h.is(":visible") ? (u = !1,
            S()) : x()
        }
          , _ = function(t) {
            window.requestAnimationFrame ? requestAnimationFrame(S) : S()
        };
        r.init = function() {
            r.options = T.extend({}, T.ScrollToFixed.defaultOptions, e),
            n = h.css("z-index"),
            r.$el.css("z-index", r.options.zIndex),
            o = T("<div />"),
            s = h.css("position"),
            a = h.css("position"),
            h.css("float"),
            i = h.css("top"),
            v() && r.$el.after(o),
            T(window).bind("resize.ScrollToFixed", z),
            T(window).bind("scroll.ScrollToFixed", _),
            "ontouchmove"in window && T(window).bind("touchmove.ScrollToFixed", S),
            r.options.preFixed && h.bind("preFixed.ScrollToFixed", r.options.preFixed),
            r.options.postFixed && h.bind("postFixed.ScrollToFixed", r.options.postFixed),
            r.options.preUnfixed && h.bind("preUnfixed.ScrollToFixed", r.options.preUnfixed),
            r.options.postUnfixed && h.bind("postUnfixed.ScrollToFixed", r.options.postUnfixed),
            r.options.preAbsolute && h.bind("preAbsolute.ScrollToFixed", r.options.preAbsolute),
            r.options.postAbsolute && h.bind("postAbsolute.ScrollToFixed", r.options.postAbsolute),
            r.options.fixed && h.bind("fixed.ScrollToFixed", r.options.fixed),
            r.options.unfixed && h.bind("unfixed.ScrollToFixed", r.options.unfixed),
            r.options.spacerClass && o.addClass(r.options.spacerClass),
            h.bind("resize.ScrollToFixed", function() {
                o.height(h.height())
            }),
            h.bind("scroll.ScrollToFixed", function() {
                h.trigger("preUnfixed.ScrollToFixed"),
                x(),
                h.trigger("unfixed.ScrollToFixed"),
                S()
            }),
            h.bind("detach.ScrollToFixed", function(t) {
                var e;
                (e = (e = t) || window.event).preventDefault && e.preventDefault(),
                e.returnValue = !1,
                h.trigger("preUnfixed.ScrollToFixed"),
                x(),
                h.trigger("unfixed.ScrollToFixed"),
                T(window).unbind("resize.ScrollToFixed", z),
                T(window).unbind("scroll.ScrollToFixed", _),
                h.unbind(".ScrollToFixed"),
                o.remove(),
                r.$el.removeData("ScrollToFixed")
            }),
            z()
        }
        ,
        r.init()
    }
    ,
    T.ScrollToFixed.defaultOptions = {
        marginTop: 0,
        limit: 0,
        bottom: -1,
        zIndex: 1e3,
        baseClassName: "scroll-to-fixed-fixed"
    },
    T.fn.scrollToFixed = function(t) {
        return this.each(function() {
            new T.ScrollToFixed(this,t)
        })
    }
}(jQuery),
function(u) {
    "use strict";
    u.fn.extend({
        customSelect: function(e) {
            if (void 0 === document.body.style.maxHeight)
                return this;
            var r = (e = u.extend({
                customClass: "customSelect",
                mapClass: !0,
                mapStyle: !0
            }, e)).customClass
              , s = function(t, e) {
                var i = t.find(":selected")
                  , n = e.children(":first")
                  , o = i.html() || "&nbsp;";
                n.html(o),
                i.attr("disabled") ? e.addClass(a("DisabledOption")) : e.removeClass(a("DisabledOption")),
                setTimeout(function() {
                    e.removeClass(a("Open")),
                    u(document).off("mouseup.customSelect")
                }, 60)
            }
              , a = function(t) {
                return r + t
            };
            return this.each(function() {
                var i = u(this)
                  , n = u("<span />").addClass(a("Inner"))
                  , t = u("<div class='arrow-down'></div>")
                  , o = u("<span />");
                i.after(o.append(n, t)),
                o.addClass(r),
                e.mapClass && o.addClass(i.attr("class")),
                e.mapStyle && o.attr("style", i.attr("style")),
                i.addClass("hasCustomSelect").on("render.customSelect", function() {
                    s(i, o),
                    i.css("width", "");
                    var t = parseInt(i.outerWidth(), 10) - (parseInt(o.outerWidth(), 10) - parseInt(o.width(), 10));
                    o.css({
                        display: "inline-block"
                    });
                    var e = o.outerHeight();
                    i.attr("disabled") ? o.addClass(a("Disabled")) : o.removeClass(a("Disabled")),
                    n.css({
                        width: t,
                        display: "inline-block"
                    }),
                    i.css({
                        "-webkit-appearance": "menulist-button",
                        width: o.outerWidth(),
                        position: "absolute",
                        opacity: 0,
                        height: e,
                        fontSize: o.css("font-size")
                    })
                }).on("change.customSelect", function() {
                    o.addClass(a("Changed")),
                    s(i, o)
                }).on("keyup.customSelect", function(t) {
                    o.hasClass(a("Open")) ? 13 != t.which && 27 != t.which || s(i, o) : (i.trigger("blur.customSelect"),
                    i.trigger("focus.customSelect"))
                }).on("mousedown.customSelect", function() {
                    o.removeClass(a("Changed"))
                }).on("mouseup.customSelect", function(t) {
                    o.hasClass(a("Open")) || (0 < u("." + a("Open")).not(o).length && "undefined" != typeof InstallTrigger ? i.trigger("focus.customSelect") : (o.addClass(a("Open")),
                    t.stopPropagation(),
                    u(document).one("mouseup.customSelect", function(t) {
                        t.target != i.get(0) && u.inArray(t.target, i.find("*").get()) < 0 ? i.trigger("blur.customSelect") : s(i, o)
                    })))
                }).on("focus.customSelect", function() {
                    o.removeClass(a("Changed")).addClass(a("Focus"))
                }).on("blur.customSelect", function() {
                    o.removeClass(a("Focus") + " " + a("Open"))
                }).on("mouseenter.customSelect", function() {
                    o.addClass(a("Hover"))
                }).on("mouseleave.customSelect", function() {
                    o.removeClass(a("Hover"))
                }).trigger("render.customSelect")
            })
        }
    })
}(jQuery),
function(t, e) {
    "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define("whatInput", [], e) : "object" == typeof exports ? exports.whatInput = e() : t.whatInput = e()
}(this, function() {
    return function(i) {
        var n = {};
        function o(t) {
            if (n[t])
                return n[t].exports;
            var e = n[t] = {
                exports: {},
                id: t,
                loaded: !1
            };
            return i[t].call(e.exports, e, e.exports, o),
            e.loaded = !0,
            e.exports
        }
        return o.m = i,
        o.c = n,
        o.p = "",
        o(0)
    }([function(t, e) {
        var i, o, r, s, a, u, n, h, l, c, d, f, p, m, g, v, y;
        t.exports = (i = document.documentElement,
        o = "initial",
        s = ["button", "input", "select", "textarea"],
        a = [16, 17, 18, 91, 93],
        u = {
            keyup: "keyboard",
            mousedown: "mouse",
            mousemove: "mouse",
            MSPointerDown: "pointer",
            MSPointerMove: "pointer",
            pointerdown: "pointer",
            pointermove: "pointer",
            touchstart: "touch"
        },
        h = !(n = []),
        l = {
            2: "touch",
            3: "touch",
            4: "mouse"
        },
        c = r = null,
        d = function() {
            window.PointerEvent ? (i.addEventListener("pointerdown", f),
            i.addEventListener("pointermove", m)) : window.MSPointerEvent ? (i.addEventListener("MSPointerDown", f),
            i.addEventListener("MSPointerMove", m)) : (i.addEventListener("mousedown", f),
            i.addEventListener("mousemove", m),
            "ontouchstart"in window && i.addEventListener("touchstart", g)),
            i.addEventListener(y(), m),
            i.addEventListener("keydown", f),
            i.addEventListener("keyup", f)
        }
        ,
        f = function(t) {
            if (!h) {
                var e = t.which
                  , i = u[t.type];
                if ("pointer" === i && (i = v(t)),
                o !== i || r !== i) {
                    var n = !(!document.activeElement || -1 !== s.indexOf(document.activeElement.nodeName.toLowerCase()));
                    ("touch" === i || "mouse" === i && -1 === a.indexOf(e) || "keyboard" === i && n) && (o = r = i,
                    p())
                }
            }
        }
        ,
        p = function() {
            i.setAttribute("data-whatinput", o),
            i.setAttribute("data-whatintent", o),
            -1 === n.indexOf(o) && (n.push(o),
            i.classList.add("whatinput-types-" + o))
        }
        ,
        m = function(t) {
            if (!h) {
                var e = u[t.type];
                "pointer" === e && (e = v(t)),
                r !== e && (r = e,
                i.setAttribute("data-whatintent", r))
            }
        }
        ,
        g = function(t) {
            window.clearTimeout(c),
            f(t),
            h = !0,
            c = window.setTimeout(function() {
                h = !1
            }, 200)
        }
        ,
        v = function(t) {
            return "number" == typeof t.pointerType ? l[t.pointerType] : "pen" === t.pointerType ? "touch" : t.pointerType
        }
        ,
        y = function() {
            return "onwheel"in document.createElement("div") ? "wheel" : void 0 !== document.onmousewheel ? "mousewheel" : "DOMMouseScroll"
        }
        ,
        "addEventListener"in window && Array.prototype.indexOf && (u[y()] = "mouse",
        d(),
        p()),
        {
            ask: function(t) {
                return "loose" === t ? r : o
            },
            types: function() {
                return n
            }
        })
    }
    ])
});
var CanvasImage = function(t) {
    this.canvas = document.createElement("canvas"),
    this.context = this.canvas.getContext("2d"),
    document.body.appendChild(this.canvas),
    this.width = this.canvas.width = t.width,
    this.height = this.canvas.height = t.height,
    this.context.drawImage(t, 0, 0, this.width, this.height)
};
CanvasImage.prototype.clear = function() {
    this.context.clearRect(0, 0, this.width, this.height)
}
,
CanvasImage.prototype.update = function(t) {
    this.context.putImageData(t, 0, 0)
}
,
CanvasImage.prototype.getPixelCount = function() {
    return this.width * this.height
}
,
CanvasImage.prototype.getImageData = function() {
    return this.context.getImageData(0, 0, this.width, this.height)
}
,
CanvasImage.prototype.removeCanvas = function() {
    this.canvas.parentNode.removeChild(this.canvas)
}
;
var ColorThief = function() {};
if (ColorThief.prototype.getColor = function(t, e) {
    return this.getPalette(t, 5, e)[0]
}
,
ColorThief.prototype.getPalette = function(t, e, i) {
    void 0 === e && (e = 10),
    (void 0 === i || i < 1) && (i = 10);
    for (var n, o, r, s, a = new CanvasImage(t), u = a.getImageData().data, h = a.getPixelCount(), l = [], c = 0; c < h; c += i)
        o = u[(n = 4 * c) + 0],
        r = u[n + 1],
        s = u[n + 2],
        125 <= u[n + 3] && (250 < o && 250 < r && 250 < s || l.push([o, r, s]));
    var d = MMCQ.quantize(l, e)
      , f = d ? d.palette() : null;
    return a.removeCanvas(),
    f
}
,
!pv)
    var pv = {
        map: function(t, i) {
            var n = {};
            return i ? t.map(function(t, e) {
                return n.index = e,
                i.call(n, t)
            }) : t.slice()
        },
        naturalOrder: function(t, e) {
            return t < e ? -1 : e < t ? 1 : 0
        },
        sum: function(t, n) {
            var o = {};
            return t.reduce(n ? function(t, e, i) {
                return o.index = i,
                t + n.call(o, e)
            }
            : function(t, e) {
                return t + e
            }
            , 0)
        },
        max: function(t, e) {
            return Math.max.apply(null, e ? pv.map(t, e) : t)
        }
    };
var MMCQ = function() {
    var C = 5
      , z = 8 - C;
    function _(t, e, i) {
        return (t << 2 * C) + (e << C) + i
    }
    function T(t) {
        var e = []
          , i = !1;
        function n() {
            e.sort(t),
            i = !0
        }
        return {
            push: function(t) {
                e.push(t),
                i = !1
            },
            peek: function(t) {
                return i || n(),
                void 0 === t && (t = e.length - 1),
                e[t]
            },
            pop: function() {
                return i || n(),
                e.pop()
            },
            size: function() {
                return e.length
            },
            map: function(t) {
                return e.map(t)
            },
            debug: function() {
                return i || n(),
                e
            }
        }
    }
    function I(t, e, i, n, o, r, s) {
        var a = this;
        a.r1 = t,
        a.r2 = e,
        a.g1 = i,
        a.g2 = n,
        a.b1 = o,
        a.b2 = r,
        a.histo = s
    }
    function L() {
        this.vboxes = new T(function(t, e) {
            return pv.naturalOrder(t.vbox.count() * t.vbox.volume(), e.vbox.count() * e.vbox.volume())
        }
        )
    }
    function k(t, h) {
        if (h.count()) {
            var e = h.r2 - h.r1 + 1
              , i = h.g2 - h.g1 + 1
              , n = h.b2 - h.b1 + 1
              , o = pv.max([e, i, n]);
            if (1 == h.count())
                return [h.copy()];
            var l, r, s, a, c = 0, d = [], f = [];
            if (o == e)
                for (l = h.r1; l <= h.r2; l++) {
                    for (a = 0,
                    r = h.g1; r <= h.g2; r++)
                        for (s = h.b1; s <= h.b2; s++)
                            a += t[_(l, r, s)] || 0;
                    c += a,
                    d[l] = c
                }
            else if (o == i)
                for (l = h.g1; l <= h.g2; l++) {
                    for (a = 0,
                    r = h.r1; r <= h.r2; r++)
                        for (s = h.b1; s <= h.b2; s++)
                            a += t[_(r, l, s)] || 0;
                    c += a,
                    d[l] = c
                }
            else
                for (l = h.b1; l <= h.b2; l++) {
                    for (a = 0,
                    r = h.r1; r <= h.r2; r++)
                        for (s = h.g1; s <= h.g2; s++)
                            a += t[_(r, s, l)] || 0;
                    c += a,
                    d[l] = c
                }
            return d.forEach(function(t, e) {
                f[e] = c - t
            }),
            u(o == e ? "r" : o == i ? "g" : "b")
        }
        function u(t) {
            var e, i, n, o, r, s = t + "1", a = t + "2", u = 0;
            for (l = h[s]; l <= h[a]; l++)
                if (d[l] > c / 2) {
                    for (n = h.copy(),
                    o = h.copy(),
                    r = (e = l - h[s]) <= (i = h[a] - l) ? Math.min(h[a] - 1, ~~(l + i / 2)) : Math.max(h[s], ~~(l - 1 - e / 2)); !d[r]; )
                        r++;
                    for (u = f[r]; !u && d[r - 1]; )
                        u = f[--r];
                    return n[a] = r,
                    o[s] = n[a] + 1,
                    [n, o]
                }
        }
    }
    return I.prototype = {
        volume: function(t) {
            var e = this;
            return e._volume && !t || (e._volume = (e.r2 - e.r1 + 1) * (e.g2 - e.g1 + 1) * (e.b2 - e.b1 + 1)),
            e._volume
        },
        count: function(t) {
            var e = this
              , i = e.histo;
            if (!e._count_set || t) {
                var n, o, r, s = 0;
                for (n = e.r1; n <= e.r2; n++)
                    for (o = e.g1; o <= e.g2; o++)
                        for (r = e.b1; r <= e.b2; r++)
                            index = _(n, o, r),
                            s += i[index] || 0;
                e._count = s,
                e._count_set = !0
            }
            return e._count
        },
        copy: function() {
            var t = this;
            return new I(t.r1,t.r2,t.g1,t.g2,t.b1,t.b2,t.histo)
        },
        avg: function(t) {
            var e = this
              , i = e.histo;
            if (!e._avg || t) {
                var n, o, r, s, a = 0, u = 1 << 8 - C, h = 0, l = 0, c = 0;
                for (o = e.r1; o <= e.r2; o++)
                    for (r = e.g1; r <= e.g2; r++)
                        for (s = e.b1; s <= e.b2; s++)
                            a += n = i[_(o, r, s)] || 0,
                            h += n * (o + .5) * u,
                            l += n * (r + .5) * u,
                            c += n * (s + .5) * u;
                e._avg = a ? [~~(h / a), ~~(l / a), ~~(c / a)] : [~~(u * (e.r1 + e.r2 + 1) / 2), ~~(u * (e.g1 + e.g2 + 1) / 2), ~~(u * (e.b1 + e.b2 + 1) / 2)]
            }
            return e._avg
        },
        contains: function(t) {
            var e = this
              , i = t[0] >> z;
            return gval = t[1] >> z,
            bval = t[2] >> z,
            i >= e.r1 && i <= e.r2 && gval >= e.g1 && gval <= e.g2 && bval >= e.b1 && bval <= e.b2
        }
    },
    L.prototype = {
        push: function(t) {
            this.vboxes.push({
                vbox: t,
                color: t.avg()
            })
        },
        palette: function() {
            return this.vboxes.map(function(t) {
                return t.color
            })
        },
        size: function() {
            return this.vboxes.size()
        },
        map: function(t) {
            for (var e = this.vboxes, i = 0; i < e.size(); i++)
                if (e.peek(i).vbox.contains(t))
                    return e.peek(i).color;
            return this.nearest(t)
        },
        nearest: function(t) {
            for (var e, i, n, o = this.vboxes, r = 0; r < o.size(); r++)
                ((i = Math.sqrt(Math.pow(t[0] - o.peek(r).color[0], 2) + Math.pow(t[1] - o.peek(r).color[1], 2) + Math.pow(t[2] - o.peek(r).color[2], 2))) < e || void 0 === e) && (e = i,
                n = o.peek(r).color);
            return n
        },
        forcebw: function() {
            var t = this.vboxes;
            t.sort(function(t, e) {
                return pv.naturalOrder(pv.sum(t.color), pv.sum(e.color))
            });
            var e = t[0].color;
            e[0] < 5 && e[1] < 5 && e[2] < 5 && (t[0].color = [0, 0, 0]);
            var i = t.length - 1
              , n = t[i].color;
            251 < n[0] && 251 < n[1] && 251 < n[2] && (t[i].color = [255, 255, 255])
        }
    },
    {
        quantize: function(t, e) {
            if (!t.length || e < 2 || 256 < e)
                return !1;
            var i, n, o, r, s, a, u = (i = t,
            a = new Array(1 << 3 * C),
            i.forEach(function(t) {
                o = t[0] >> z,
                r = t[1] >> z,
                s = t[2] >> z,
                n = _(o, r, s),
                a[n] = (a[n] || 0) + 1
            }),
            a);
            u.forEach(function() {});
            var h, l, c, d, f, p, m, g, v, y, b = (h = u,
            v = m = f = 1e6,
            y = g = p = 0,
            t.forEach(function(t) {
                l = t[0] >> z,
                c = t[1] >> z,
                d = t[2] >> z,
                l < f ? f = l : p < l && (p = l),
                c < m ? m = c : g < c && (g = c),
                d < v ? v = d : y < d && (y = d)
            }),
            new I(f,p,m,g,v,y,h)), x = new T(function(t, e) {
                return pv.naturalOrder(t.count(), e.count())
            }
            );
            function w(t, e) {
                for (var i, n = 1, o = 0; o < 1e3; )
                    if ((i = t.pop()).count()) {
                        var r = k(u, i)
                          , s = r[0]
                          , a = r[1];
                        if (!s)
                            return;
                        if (t.push(s),
                        a && (t.push(a),
                        n++),
                        e <= n)
                            return;
                        if (1e3 < o++)
                            return
                    } else
                        t.push(i),
                        o++
            }
            x.push(b),
            w(x, .75 * e);
            for (var E = new T(function(t, e) {
                return pv.naturalOrder(t.count() * t.volume(), e.count() * e.volume())
            }
            ); x.size(); )
                E.push(x.pop());
            w(E, e - E.size());
            for (var S = new L; E.size(); )
                S.push(E.pop());
            return S
        }
    }
}();
!function(t, e) {
    "function" == typeof define && define.amd ? define("ev-emitter/ev-emitter", e) : "object" == typeof module && module.exports ? module.exports = e() : t.EvEmitter = e()
}("undefined" != typeof window ? window : this, function() {
    function t() {}
    var e = t.prototype;
    return e.on = function(t, e) {
        if (t && e) {
            var i = this._events = this._events || {}
              , n = i[t] = i[t] || [];
            return -1 == n.indexOf(e) && n.push(e),
            this
        }
    }
    ,
    e.once = function(t, e) {
        if (t && e) {
            this.on(t, e);
            var i = this._onceEvents = this._onceEvents || {};
            return (i[t] = i[t] || {})[e] = !0,
            this
        }
    }
    ,
    e.off = function(t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
            var n = i.indexOf(e);
            return -1 != n && i.splice(n, 1),
            this
        }
    }
    ,
    e.emitEvent = function(t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
            var n = 0
              , o = i[n];
            e = e || [];
            for (var r = this._onceEvents && this._onceEvents[t]; o; ) {
                var s = r && r[o];
                s && (this.off(t, o),
                delete r[o]),
                o.apply(this, e),
                o = i[n += s ? 0 : 1]
            }
            return this
        }
    }
    ,
    t
}),
function(e, i) {
    "use strict";
    "function" == typeof define && define.amd ? define(["ev-emitter/ev-emitter"], function(t) {
        return i(e, t)
    }) : "object" == typeof module && module.exports ? module.exports = i(e, require("ev-emitter")) : e.imagesLoaded = i(e, e.EvEmitter)
}(window, function(e, t) {
    function n(t, e) {
        for (var i in e)
            t[i] = e[i];
        return t
    }
    function o(t, e, i) {
        return this instanceof o ? ("string" == typeof t && (t = document.querySelectorAll(t)),
        this.elements = function(t) {
            var e = [];
            if (Array.isArray(t))
                e = t;
            else if ("number" == typeof t.length)
                for (var i = 0; i < t.length; i++)
                    e.push(t[i]);
            else
                e.push(t);
            return e
        }(t),
        this.options = n({}, this.options),
        "function" == typeof e ? i = e : n(this.options, e),
        i && this.on("always", i),
        this.getImages(),
        s && (this.jqDeferred = new s.Deferred),
        void setTimeout(function() {
            this.check()
        }
        .bind(this))) : new o(t,e,i)
    }
    function i(t) {
        this.img = t
    }
    function r(t, e) {
        this.url = t,
        this.element = e,
        this.img = new Image
    }
    var s = e.jQuery
      , a = e.console;
    (o.prototype = Object.create(t.prototype)).options = {},
    o.prototype.getImages = function() {
        this.images = [],
        this.elements.forEach(this.addElementImages, this)
    }
    ,
    o.prototype.addElementImages = function(t) {
        "IMG" == t.nodeName && this.addImage(t),
        !0 === this.options.background && this.addElementBackgroundImages(t);
        var e = t.nodeType;
        if (e && u[e]) {
            for (var i = t.querySelectorAll("img"), n = 0; n < i.length; n++) {
                var o = i[n];
                this.addImage(o)
            }
            if ("string" == typeof this.options.background) {
                var r = t.querySelectorAll(this.options.background);
                for (n = 0; n < r.length; n++) {
                    var s = r[n];
                    this.addElementBackgroundImages(s)
                }
            }
        }
    }
    ;
    var u = {
        1: !0,
        9: !0,
        11: !0
    };
    return o.prototype.addElementBackgroundImages = function(t) {
        var e = getComputedStyle(t);
        if (e)
            for (var i = /url\((['"])?(.*?)\1\)/gi, n = i.exec(e.backgroundImage); null !== n; ) {
                var o = n && n[2];
                o && this.addBackground(o, t),
                n = i.exec(e.backgroundImage)
            }
    }
    ,
    o.prototype.addImage = function(t) {
        var e = new i(t);
        this.images.push(e)
    }
    ,
    o.prototype.addBackground = function(t, e) {
        var i = new r(t,e);
        this.images.push(i)
    }
    ,
    o.prototype.check = function() {
        function e(t, e, i) {
            setTimeout(function() {
                n.progress(t, e, i)
            })
        }
        var n = this;
        return this.progressedCount = 0,
        this.hasAnyBroken = !1,
        this.images.length ? void this.images.forEach(function(t) {
            t.once("progress", e),
            t.check()
        }) : void this.complete()
    }
    ,
    o.prototype.progress = function(t, e, i) {
        this.progressedCount++,
        this.hasAnyBroken = this.hasAnyBroken || !t.isLoaded,
        this.emitEvent("progress", [this, t, e]),
        this.jqDeferred && this.jqDeferred.notify && this.jqDeferred.notify(this, t),
        this.progressedCount == this.images.length && this.complete(),
        this.options.debug && a && a.log("progress: " + i, t, e)
    }
    ,
    o.prototype.complete = function() {
        var t = this.hasAnyBroken ? "fail" : "done";
        if (this.isComplete = !0,
        this.emitEvent(t, [this]),
        this.emitEvent("always", [this]),
        this.jqDeferred) {
            var e = this.hasAnyBroken ? "reject" : "resolve";
            this.jqDeferred[e](this)
        }
    }
    ,
    (i.prototype = Object.create(t.prototype)).check = function() {
        return this.getIsImageComplete() ? void this.confirm(0 !== this.img.naturalWidth, "naturalWidth") : (this.proxyImage = new Image,
        this.proxyImage.addEventListener("load", this),
        this.proxyImage.addEventListener("error", this),
        this.img.addEventListener("load", this),
        this.img.addEventListener("error", this),
        void (this.proxyImage.src = this.img.src))
    }
    ,
    i.prototype.getIsImageComplete = function() {
        return this.img.complete && void 0 !== this.img.naturalWidth
    }
    ,
    i.prototype.confirm = function(t, e) {
        this.isLoaded = t,
        this.emitEvent("progress", [this, this.img, e])
    }
    ,
    i.prototype.handleEvent = function(t) {
        var e = "on" + t.type;
        this[e] && this[e](t)
    }
    ,
    i.prototype.onload = function() {
        this.confirm(!0, "onload"),
        this.unbindEvents()
    }
    ,
    i.prototype.onerror = function() {
        this.confirm(!1, "onerror"),
        this.unbindEvents()
    }
    ,
    i.prototype.unbindEvents = function() {
        this.proxyImage.removeEventListener("load", this),
        this.proxyImage.removeEventListener("error", this),
        this.img.removeEventListener("load", this),
        this.img.removeEventListener("error", this)
    }
    ,
    (r.prototype = Object.create(i.prototype)).check = function() {
        this.img.addEventListener("load", this),
        this.img.addEventListener("error", this),
        this.img.src = this.url,
        this.getIsImageComplete() && (this.confirm(0 !== this.img.naturalWidth, "naturalWidth"),
        this.unbindEvents())
    }
    ,
    r.prototype.unbindEvents = function() {
        this.img.removeEventListener("load", this),
        this.img.removeEventListener("error", this)
    }
    ,
    r.prototype.confirm = function(t, e) {
        this.isLoaded = t,
        this.emitEvent("progress", [this, this.element, e])
    }
    ,
    o.makeJQueryPlugin = function(t) {
        (t = t || e.jQuery) && ((s = t).fn.imagesLoaded = function(t, e) {
            return new o(this,t,e).jqDeferred.promise(s(this))
        }
        )
    }
    ,
    o.makeJQueryPlugin(),
    o
}),
function(e, i) {
    "use strict";
    "function" == typeof define && define.amd ? define("jquery-bridget/jquery-bridget", ["jquery"], function(t) {
        i(e, t)
    }) : "object" == typeof module && module.exports ? module.exports = i(e, require("jquery")) : e.jQueryBridget = i(e, e.jQuery)
}(window, function(t, e) {
    "use strict";
    function i(h, o, l) {
        (l = l || e || t.jQuery) && (o.prototype.option || (o.prototype.option = function(t) {
            l.isPlainObject(t) && (this.options = l.extend(!0, this.options, t))
        }
        ),
        l.fn[h] = function(t) {
            if ("string" == typeof t) {
                var e = c.call(arguments, 1);
                return s = e,
                u = "$()." + h + '("' + (r = t) + '")',
                (i = this).each(function(t, e) {
                    var i = l.data(e, h);
                    if (i) {
                        var n = i[r];
                        if (n && "_" != r.charAt(0)) {
                            var o = n.apply(i, s);
                            a = void 0 === a ? o : a
                        } else
                            d(u + " is not a valid method")
                    } else
                        d(h + " not initialized. Cannot call methods, i.e. " + u)
                }),
                void 0 !== a ? a : i
            }
            var i, r, s, a, u, n;
            return n = t,
            this.each(function(t, e) {
                var i = l.data(e, h);
                i ? (i.option(n),
                i._init()) : (i = new o(e,n),
                l.data(e, h, i))
            }),
            this
        }
        ,
        n(l))
    }
    function n(t) {
        !t || t && t.bridget || (t.bridget = i)
    }
    var c = Array.prototype.slice
      , o = t.console
      , d = void 0 === o ? function() {}
    : function(t) {
        o.error(t)
    }
    ;
    return n(e || t.jQuery),
    i
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("ev-emitter/ev-emitter", e) : "object" == typeof module && module.exports ? module.exports = e() : t.EvEmitter = e()
}("undefined" != typeof window ? window : this, function() {
    function t() {}
    var e = t.prototype;
    return e.on = function(t, e) {
        if (t && e) {
            var i = this._events = this._events || {}
              , n = i[t] = i[t] || [];
            return -1 == n.indexOf(e) && n.push(e),
            this
        }
    }
    ,
    e.once = function(t, e) {
        if (t && e) {
            this.on(t, e);
            var i = this._onceEvents = this._onceEvents || {};
            return (i[t] = i[t] || {})[e] = !0,
            this
        }
    }
    ,
    e.off = function(t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
            var n = i.indexOf(e);
            return -1 != n && i.splice(n, 1),
            this
        }
    }
    ,
    e.emitEvent = function(t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
            var n = 0
              , o = i[n];
            e = e || [];
            for (var r = this._onceEvents && this._onceEvents[t]; o; ) {
                var s = r && r[o];
                s && (this.off(t, o),
                delete r[o]),
                o.apply(this, e),
                o = i[n += s ? 0 : 1]
            }
            return this
        }
    }
    ,
    t
}),
function(t, e) {
    "use strict";
    "function" == typeof define && define.amd ? define("get-size/get-size", [], function() {
        return e()
    }) : "object" == typeof module && module.exports ? module.exports = e() : t.getSize = e()
}(window, function() {
    "use strict";
    function v(t) {
        var e = parseFloat(t);
        return -1 == t.indexOf("%") && !isNaN(e) && e
    }
    function y(t) {
        var e = getComputedStyle(t);
        return e || i("Style returned " + e + ". Are you running this code in a hidden iframe on Firefox? See http://bit.ly/getsizebug1"),
        e
    }
    function b(t) {
        if (function() {
            if (!S) {
                S = !0;
                var t = document.createElement("div");
                t.style.width = "200px",
                t.style.padding = "1px 2px 3px 4px",
                t.style.borderStyle = "solid",
                t.style.borderWidth = "1px 2px 3px 4px",
                t.style.boxSizing = "border-box";
                var e = document.body || document.documentElement;
                e.appendChild(t);
                var i = y(t);
                b.isBoxSizeOuter = x = 200 == v(i.width),
                e.removeChild(t)
            }
        }(),
        "string" == typeof t && (t = document.querySelector(t)),
        t && "object" == typeof t && t.nodeType) {
            var e = y(t);
            if ("none" == e.display)
                return function() {
                    for (var t = {
                        width: 0,
                        height: 0,
                        innerWidth: 0,
                        innerHeight: 0,
                        outerWidth: 0,
                        outerHeight: 0
                    }, e = 0; e < E; e++)
                        t[w[e]] = 0;
                    return t
                }();
            var i = {};
            i.width = t.offsetWidth,
            i.height = t.offsetHeight;
            for (var n = i.isBorderBox = "border-box" == e.boxSizing, o = 0; o < E; o++) {
                var r = w[o]
                  , s = e[r]
                  , a = parseFloat(s);
                i[r] = isNaN(a) ? 0 : a
            }
            var u = i.paddingLeft + i.paddingRight
              , h = i.paddingTop + i.paddingBottom
              , l = i.marginLeft + i.marginRight
              , c = i.marginTop + i.marginBottom
              , d = i.borderLeftWidth + i.borderRightWidth
              , f = i.borderTopWidth + i.borderBottomWidth
              , p = n && x
              , m = v(e.width);
            !1 !== m && (i.width = m + (p ? 0 : u + d));
            var g = v(e.height);
            return !1 !== g && (i.height = g + (p ? 0 : h + f)),
            i.innerWidth = i.width - (u + d),
            i.innerHeight = i.height - (h + f),
            i.outerWidth = i.width + l,
            i.outerHeight = i.height + c,
            i
        }
    }
    var x, i = "undefined" == typeof console ? function() {}
    : function(t) {
        console.error(t)
    }
    , w = ["paddingLeft", "paddingRight", "paddingTop", "paddingBottom", "marginLeft", "marginRight", "marginTop", "marginBottom", "borderLeftWidth", "borderRightWidth", "borderTopWidth", "borderBottomWidth"], E = w.length, S = !1;
    return b
}),
function(t, e) {
    "use strict";
    "function" == typeof define && define.amd ? define("desandro-matches-selector/matches-selector", e) : "object" == typeof module && module.exports ? module.exports = e() : t.matchesSelector = e()
}(window, function() {
    "use strict";
    var i = function() {
        var t = Element.prototype;
        if (t.matches)
            return "matches";
        if (t.matchesSelector)
            return "matchesSelector";
        for (var e = ["webkit", "moz", "ms", "o"], i = 0; i < e.length; i++) {
            var n = e[i] + "MatchesSelector";
            if (t[n])
                return n
        }
    }();
    return function(t, e) {
        return t[i](e)
    }
}),
function(e, i) {
    "function" == typeof define && define.amd ? define("fizzy-ui-utils/utils", ["desandro-matches-selector/matches-selector"], function(t) {
        return i(e, t)
    }) : "object" == typeof module && module.exports ? module.exports = i(e, require("desandro-matches-selector")) : e.fizzyUIUtils = i(e, e.matchesSelector)
}(window, function(h, r) {
    var l = {
        extend: function(t, e) {
            for (var i in e)
                t[i] = e[i];
            return t
        },
        modulo: function(t, e) {
            return (t % e + e) % e
        },
        makeArray: function(t) {
            var e = [];
            if (Array.isArray(t))
                e = t;
            else if (t && "number" == typeof t.length)
                for (var i = 0; i < t.length; i++)
                    e.push(t[i]);
            else
                e.push(t);
            return e
        },
        removeFrom: function(t, e) {
            var i = t.indexOf(e);
            -1 != i && t.splice(i, 1)
        },
        getParent: function(t, e) {
            for (; t != document.body; )
                if (t = t.parentNode,
                r(t, e))
                    return t
        },
        getQueryElement: function(t) {
            return "string" == typeof t ? document.querySelector(t) : t
        },
        handleEvent: function(t) {
            var e = "on" + t.type;
            this[e] && this[e](t)
        },
        filterFindElements: function(t, n) {
            t = l.makeArray(t);
            var o = [];
            return t.forEach(function(t) {
                if (t instanceof HTMLElement) {
                    if (!n)
                        return void o.push(t);
                    r(t, n) && o.push(t);
                    for (var e = t.querySelectorAll(n), i = 0; i < e.length; i++)
                        o.push(e[i])
                }
            }),
            o
        },
        debounceMethod: function(t, e, n) {
            var o = t.prototype[e]
              , r = e + "Timeout";
            t.prototype[e] = function() {
                var t = this[r];
                t && clearTimeout(t);
                var e = arguments
                  , i = this;
                this[r] = setTimeout(function() {
                    o.apply(i, e),
                    delete i[r]
                }, n || 100)
            }
        },
        docReady: function(t) {
            var e = document.readyState;
            "complete" == e || "interactive" == e ? t() : document.addEventListener("DOMContentLoaded", t)
        },
        toDashed: function(t) {
            return t.replace(/(.)([A-Z])/g, function(t, e, i) {
                return e + "-" + i
            }).toLowerCase()
        }
    }
      , c = h.console;
    return l.htmlInit = function(a, u) {
        l.docReady(function() {
            var t = l.toDashed(u)
              , o = "data-" + t
              , e = document.querySelectorAll("[" + o + "]")
              , i = document.querySelectorAll(".js-" + t)
              , n = l.makeArray(e).concat(l.makeArray(i))
              , r = o + "-options"
              , s = h.jQuery;
            n.forEach(function(e) {
                var t, i = e.getAttribute(o) || e.getAttribute(r);
                try {
                    t = i && JSON.parse(i)
                } catch (t) {
                    return void (c && c.error("Error parsing " + o + " on " + e.className + ": " + t))
                }
                var n = new a(e,t);
                s && s.data(e, u, n)
            })
        })
    }
    ,
    l
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("outlayer/item", ["ev-emitter/ev-emitter", "get-size/get-size"], e) : "object" == typeof module && module.exports ? module.exports = e(require("ev-emitter"), require("get-size")) : (t.Outlayer = {},
    t.Outlayer.Item = e(t.EvEmitter, t.getSize))
}(window, function(t, e) {
    "use strict";
    function i(t, e) {
        t && (this.element = t,
        this.layout = e,
        this.position = {
            x: 0,
            y: 0
        },
        this._create())
    }
    var n = document.documentElement.style
      , o = "string" == typeof n.transition ? "transition" : "WebkitTransition"
      , r = "string" == typeof n.transform ? "transform" : "WebkitTransform"
      , s = {
        WebkitTransition: "webkitTransitionEnd",
        transition: "transitionend"
    }[o]
      , a = {
        transform: r,
        transition: o,
        transitionDuration: o + "Duration",
        transitionProperty: o + "Property",
        transitionDelay: o + "Delay"
    }
      , u = i.prototype = Object.create(t.prototype);
    u.constructor = i,
    u._create = function() {
        this._transn = {
            ingProperties: {},
            clean: {},
            onEnd: {}
        },
        this.css({
            position: "absolute"
        })
    }
    ,
    u.handleEvent = function(t) {
        var e = "on" + t.type;
        this[e] && this[e](t)
    }
    ,
    u.getSize = function() {
        this.size = e(this.element)
    }
    ,
    u.css = function(t) {
        var e = this.element.style;
        for (var i in t) {
            e[a[i] || i] = t[i]
        }
    }
    ,
    u.getPosition = function() {
        var t = getComputedStyle(this.element)
          , e = this.layout._getOption("originLeft")
          , i = this.layout._getOption("originTop")
          , n = t[e ? "left" : "right"]
          , o = t[i ? "top" : "bottom"]
          , r = this.layout.size
          , s = -1 != n.indexOf("%") ? parseFloat(n) / 100 * r.width : parseInt(n, 10)
          , a = -1 != o.indexOf("%") ? parseFloat(o) / 100 * r.height : parseInt(o, 10);
        s = isNaN(s) ? 0 : s,
        a = isNaN(a) ? 0 : a,
        s -= e ? r.paddingLeft : r.paddingRight,
        a -= i ? r.paddingTop : r.paddingBottom,
        this.position.x = s,
        this.position.y = a
    }
    ,
    u.layoutPosition = function() {
        var t = this.layout.size
          , e = {}
          , i = this.layout._getOption("originLeft")
          , n = this.layout._getOption("originTop")
          , o = i ? "paddingLeft" : "paddingRight"
          , r = i ? "left" : "right"
          , s = i ? "right" : "left"
          , a = this.position.x + t[o];
        e[r] = this.getXValue(a),
        e[s] = "";
        var u = n ? "paddingTop" : "paddingBottom"
          , h = n ? "top" : "bottom"
          , l = n ? "bottom" : "top"
          , c = this.position.y + t[u];
        e[h] = this.getYValue(c),
        e[l] = "",
        this.css(e),
        this.emitEvent("layout", [this])
    }
    ,
    u.getXValue = function(t) {
        var e = this.layout._getOption("horizontal");
        return this.layout.options.percentPosition && !e ? t / this.layout.size.width * 100 + "%" : t + "px"
    }
    ,
    u.getYValue = function(t) {
        var e = this.layout._getOption("horizontal");
        return this.layout.options.percentPosition && e ? t / this.layout.size.height * 100 + "%" : t + "px"
    }
    ,
    u._transitionTo = function(t, e) {
        this.getPosition();
        var i = this.position.x
          , n = this.position.y
          , o = parseInt(t, 10)
          , r = parseInt(e, 10)
          , s = o === this.position.x && r === this.position.y;
        if (this.setPosition(t, e),
        !s || this.isTransitioning) {
            var a = t - i
              , u = e - n
              , h = {};
            h.transform = this.getTranslate(a, u),
            this.transition({
                to: h,
                onTransitionEnd: {
                    transform: this.layoutPosition
                },
                isCleaning: !0
            })
        } else
            this.layoutPosition()
    }
    ,
    u.getTranslate = function(t, e) {
        return "translate3d(" + (t = this.layout._getOption("originLeft") ? t : -t) + "px, " + (e = this.layout._getOption("originTop") ? e : -e) + "px, 0)"
    }
    ,
    u.goTo = function(t, e) {
        this.setPosition(t, e),
        this.layoutPosition()
    }
    ,
    u.moveTo = u._transitionTo,
    u.setPosition = function(t, e) {
        this.position.x = parseInt(t, 10),
        this.position.y = parseInt(e, 10)
    }
    ,
    u._nonTransition = function(t) {
        for (var e in this.css(t.to),
        t.isCleaning && this._removeStyles(t.to),
        t.onTransitionEnd)
            t.onTransitionEnd[e].call(this)
    }
    ,
    u.transition = function(t) {
        if (parseFloat(this.layout.options.transitionDuration)) {
            var e = this._transn;
            for (var i in t.onTransitionEnd)
                e.onEnd[i] = t.onTransitionEnd[i];
            for (i in t.to)
                e.ingProperties[i] = !0,
                t.isCleaning && (e.clean[i] = !0);
            if (t.from) {
                this.css(t.from);
                this.element.offsetHeight;
                null
            }
            this.enableTransition(t.to),
            this.css(t.to),
            this.isTransitioning = !0
        } else
            this._nonTransition(t)
    }
    ;
    var h = "opacity," + r.replace(/([A-Z])/g, function(t) {
        return "-" + t.toLowerCase()
    });
    u.enableTransition = function() {
        if (!this.isTransitioning) {
            var t = this.layout.options.transitionDuration;
            t = "number" == typeof t ? t + "ms" : t,
            this.css({
                transitionProperty: h,
                transitionDuration: t,
                transitionDelay: this.staggerDelay || 0
            }),
            this.element.addEventListener(s, this, !1)
        }
    }
    ,
    u.onwebkitTransitionEnd = function(t) {
        this.ontransitionend(t)
    }
    ,
    u.onotransitionend = function(t) {
        this.ontransitionend(t)
    }
    ;
    var l = {
        "-webkit-transform": "transform"
    };
    u.ontransitionend = function(t) {
        if (t.target === this.element) {
            var e = this._transn
              , i = l[t.propertyName] || t.propertyName;
            if (delete e.ingProperties[i],
            function(t) {
                for (var e in t)
                    return !1;
                return !0
            }(e.ingProperties) && this.disableTransition(),
            i in e.clean && (this.element.style[t.propertyName] = "",
            delete e.clean[i]),
            i in e.onEnd)
                e.onEnd[i].call(this),
                delete e.onEnd[i];
            this.emitEvent("transitionEnd", [this])
        }
    }
    ,
    u.disableTransition = function() {
        this.removeTransitionStyles(),
        this.element.removeEventListener(s, this, !1),
        this.isTransitioning = !1
    }
    ,
    u._removeStyles = function(t) {
        var e = {};
        for (var i in t)
            e[i] = "";
        this.css(e)
    }
    ;
    var c = {
        transitionProperty: "",
        transitionDuration: "",
        transitionDelay: ""
    };
    return u.removeTransitionStyles = function() {
        this.css(c)
    }
    ,
    u.stagger = function(t) {
        t = isNaN(t) ? 0 : t,
        this.staggerDelay = t + "ms"
    }
    ,
    u.removeElem = function() {
        this.element.parentNode.removeChild(this.element),
        this.css({
            display: ""
        }),
        this.emitEvent("remove", [this])
    }
    ,
    u.remove = function() {
        return o && parseFloat(this.layout.options.transitionDuration) ? (this.once("transitionEnd", function() {
            this.removeElem()
        }),
        void this.hide()) : void this.removeElem()
    }
    ,
    u.reveal = function() {
        delete this.isHidden,
        this.css({
            display: ""
        });
        var t = this.layout.options
          , e = {};
        e[this.getHideRevealTransitionEndProperty("visibleStyle")] = this.onRevealTransitionEnd,
        this.transition({
            from: t.hiddenStyle,
            to: t.visibleStyle,
            isCleaning: !0,
            onTransitionEnd: e
        })
    }
    ,
    u.onRevealTransitionEnd = function() {
        this.isHidden || this.emitEvent("reveal")
    }
    ,
    u.getHideRevealTransitionEndProperty = function(t) {
        var e = this.layout.options[t];
        if (e.opacity)
            return "opacity";
        for (var i in e)
            return i
    }
    ,
    u.hide = function() {
        this.isHidden = !0,
        this.css({
            display: ""
        });
        var t = this.layout.options
          , e = {};
        e[this.getHideRevealTransitionEndProperty("hiddenStyle")] = this.onHideTransitionEnd,
        this.transition({
            from: t.visibleStyle,
            to: t.hiddenStyle,
            isCleaning: !0,
            onTransitionEnd: e
        })
    }
    ,
    u.onHideTransitionEnd = function() {
        this.isHidden && (this.css({
            display: "none"
        }),
        this.emitEvent("hide"))
    }
    ,
    u.destroy = function() {
        this.css({
            position: "",
            left: "",
            right: "",
            top: "",
            bottom: "",
            transition: "",
            transform: ""
        })
    }
    ,
    i
}),
function(o, r) {
    "use strict";
    "function" == typeof define && define.amd ? define("outlayer/outlayer", ["ev-emitter/ev-emitter", "get-size/get-size", "fizzy-ui-utils/utils", "./item"], function(t, e, i, n) {
        return r(o, t, e, i, n)
    }) : "object" == typeof module && module.exports ? module.exports = r(o, require("ev-emitter"), require("get-size"), require("fizzy-ui-utils"), require("./item")) : o.Outlayer = r(o, o.EvEmitter, o.getSize, o.fizzyUIUtils, o.Outlayer.Item)
}(window, function(t, e, o, r, n) {
    "use strict";
    function s(t, e) {
        var i = r.getQueryElement(t);
        if (i) {
            this.element = i,
            h && (this.$element = h(this.element)),
            this.options = r.extend({}, this.constructor.defaults),
            this.option(e);
            var n = ++l;
            this.element.outlayerGUID = n,
            (c[n] = this)._create(),
            this._getOption("initLayout") && this.layout()
        } else
            u && u.error("Bad element for " + this.constructor.namespace + ": " + (i || t))
    }
    function a(t) {
        function e() {
            t.apply(this, arguments)
        }
        return (e.prototype = Object.create(t.prototype)).constructor = e
    }
    var u = t.console
      , h = t.jQuery
      , i = function() {}
      , l = 0
      , c = {};
    s.namespace = "outlayer",
    s.Item = n,
    s.defaults = {
        containerStyle: {
            position: "relative"
        },
        initLayout: !0,
        originLeft: !0,
        originTop: !0,
        resize: !0,
        resizeContainer: !0,
        transitionDuration: "0.4s",
        hiddenStyle: {
            opacity: 0,
            transform: "scale(0.001)"
        },
        visibleStyle: {
            opacity: 1,
            transform: "scale(1)"
        }
    };
    var d = s.prototype;
    r.extend(d, e.prototype),
    d.option = function(t) {
        r.extend(this.options, t)
    }
    ,
    d._getOption = function(t) {
        var e = this.constructor.compatOptions[t];
        return e && void 0 !== this.options[e] ? this.options[e] : this.options[t]
    }
    ,
    s.compatOptions = {
        initLayout: "isInitLayout",
        horizontal: "isHorizontal",
        layoutInstant: "isLayoutInstant",
        originLeft: "isOriginLeft",
        originTop: "isOriginTop",
        resize: "isResizeBound",
        resizeContainer: "isResizingContainer"
    },
    d._create = function() {
        this.reloadItems(),
        this.stamps = [],
        this.stamp(this.options.stamp),
        r.extend(this.element.style, this.options.containerStyle),
        this._getOption("resize") && this.bindResize()
    }
    ,
    d.reloadItems = function() {
        this.items = this._itemize(this.element.children)
    }
    ,
    d._itemize = function(t) {
        for (var e = this._filterFindItemElements(t), i = this.constructor.Item, n = [], o = 0; o < e.length; o++) {
            var r = new i(e[o],this);
            n.push(r)
        }
        return n
    }
    ,
    d._filterFindItemElements = function(t) {
        return r.filterFindElements(t, this.options.itemSelector)
    }
    ,
    d.getItemElements = function() {
        return this.items.map(function(t) {
            return t.element
        })
    }
    ,
    d.layout = function() {
        this._resetLayout(),
        this._manageStamps();
        var t = this._getOption("layoutInstant")
          , e = void 0 !== t ? t : !this._isLayoutInited;
        this.layoutItems(this.items, e),
        this._isLayoutInited = !0
    }
    ,
    d._init = d.layout,
    d._resetLayout = function() {
        this.getSize()
    }
    ,
    d.getSize = function() {
        this.size = o(this.element)
    }
    ,
    d._getMeasurement = function(t, e) {
        var i, n = this.options[t];
        n ? ("string" == typeof n ? i = this.element.querySelector(n) : n instanceof HTMLElement && (i = n),
        this[t] = i ? o(i)[e] : n) : this[t] = 0
    }
    ,
    d.layoutItems = function(t, e) {
        t = this._getItemsForLayout(t),
        this._layoutItems(t, e),
        this._postLayout()
    }
    ,
    d._getItemsForLayout = function(t) {
        return t.filter(function(t) {
            return !t.isIgnored
        })
    }
    ,
    d._layoutItems = function(t, i) {
        if (this._emitCompleteOnItems("layout", t),
        t && t.length) {
            var n = [];
            t.forEach(function(t) {
                var e = this._getItemLayoutPosition(t);
                e.item = t,
                e.isInstant = i || t.isLayoutInstant,
                n.push(e)
            }, this),
            this._processLayoutQueue(n)
        }
    }
    ,
    d._getItemLayoutPosition = function() {
        return {
            x: 0,
            y: 0
        }
    }
    ,
    d._processLayoutQueue = function(t) {
        this.updateStagger(),
        t.forEach(function(t, e) {
            this._positionItem(t.item, t.x, t.y, t.isInstant, e)
        }, this)
    }
    ,
    d.updateStagger = function() {
        var t = this.options.stagger;
        return null == t ? void (this.stagger = 0) : (this.stagger = function(t) {
            if ("number" == typeof t)
                return t;
            var e = t.match(/(^\d*\.?\d*)(\w*)/)
              , i = e && e[1]
              , n = e && e[2];
            return i.length ? (i = parseFloat(i)) * (f[n] || 1) : 0
        }(t),
        this.stagger)
    }
    ,
    d._positionItem = function(t, e, i, n, o) {
        n ? t.goTo(e, i) : (t.stagger(o * this.stagger),
        t.moveTo(e, i))
    }
    ,
    d._postLayout = function() {
        this.resizeContainer()
    }
    ,
    d.resizeContainer = function() {
        if (this._getOption("resizeContainer")) {
            var t = this._getContainerSize();
            t && (this._setContainerMeasure(t.width, !0),
            this._setContainerMeasure(t.height, !1))
        }
    }
    ,
    d._getContainerSize = i,
    d._setContainerMeasure = function(t, e) {
        if (void 0 !== t) {
            var i = this.size;
            i.isBorderBox && (t += e ? i.paddingLeft + i.paddingRight + i.borderLeftWidth + i.borderRightWidth : i.paddingBottom + i.paddingTop + i.borderTopWidth + i.borderBottomWidth),
            t = Math.max(t, 0),
            this.element.style[e ? "width" : "height"] = t + "px"
        }
    }
    ,
    d._emitCompleteOnItems = function(e, t) {
        function i() {
            o.dispatchEvent(e + "Complete", null, [t])
        }
        function n() {
            ++s == r && i()
        }
        var o = this
          , r = t.length;
        if (t && r) {
            var s = 0;
            t.forEach(function(t) {
                t.once(e, n)
            })
        } else
            i()
    }
    ,
    d.dispatchEvent = function(t, e, i) {
        var n = e ? [e].concat(i) : i;
        if (this.emitEvent(t, n),
        h)
            if (this.$element = this.$element || h(this.element),
            e) {
                var o = h.Event(e);
                o.type = t,
                this.$element.trigger(o, i)
            } else
                this.$element.trigger(t, i)
    }
    ,
    d.ignore = function(t) {
        var e = this.getItem(t);
        e && (e.isIgnored = !0)
    }
    ,
    d.unignore = function(t) {
        var e = this.getItem(t);
        e && delete e.isIgnored
    }
    ,
    d.stamp = function(t) {
        (t = this._find(t)) && (this.stamps = this.stamps.concat(t),
        t.forEach(this.ignore, this))
    }
    ,
    d.unstamp = function(t) {
        (t = this._find(t)) && t.forEach(function(t) {
            r.removeFrom(this.stamps, t),
            this.unignore(t)
        }, this)
    }
    ,
    d._find = function(t) {
        return t ? ("string" == typeof t && (t = this.element.querySelectorAll(t)),
        t = r.makeArray(t)) : void 0
    }
    ,
    d._manageStamps = function() {
        this.stamps && this.stamps.length && (this._getBoundingRect(),
        this.stamps.forEach(this._manageStamp, this))
    }
    ,
    d._getBoundingRect = function() {
        var t = this.element.getBoundingClientRect()
          , e = this.size;
        this._boundingRect = {
            left: t.left + e.paddingLeft + e.borderLeftWidth,
            top: t.top + e.paddingTop + e.borderTopWidth,
            right: t.right - (e.paddingRight + e.borderRightWidth),
            bottom: t.bottom - (e.paddingBottom + e.borderBottomWidth)
        }
    }
    ,
    d._manageStamp = i,
    d._getElementOffset = function(t) {
        var e = t.getBoundingClientRect()
          , i = this._boundingRect
          , n = o(t);
        return {
            left: e.left - i.left - n.marginLeft,
            top: e.top - i.top - n.marginTop,
            right: i.right - e.right - n.marginRight,
            bottom: i.bottom - e.bottom - n.marginBottom
        }
    }
    ,
    d.handleEvent = r.handleEvent,
    d.bindResize = function() {
        t.addEventListener("resize", this),
        this.isResizeBound = !0
    }
    ,
    d.unbindResize = function() {
        t.removeEventListener("resize", this),
        this.isResizeBound = !1
    }
    ,
    d.onresize = function() {
        this.resize()
    }
    ,
    r.debounceMethod(s, "onresize", 100),
    d.resize = function() {
        this.isResizeBound && this.needsResizeLayout() && this.layout()
    }
    ,
    d.needsResizeLayout = function() {
        var t = o(this.element);
        return this.size && t && t.innerWidth !== this.size.innerWidth
    }
    ,
    d.addItems = function(t) {
        var e = this._itemize(t);
        return e.length && (this.items = this.items.concat(e)),
        e
    }
    ,
    d.appended = function(t) {
        var e = this.addItems(t);
        e.length && (this.layoutItems(e, !0),
        this.reveal(e))
    }
    ,
    d.prepended = function(t) {
        var e = this._itemize(t);
        if (e.length) {
            var i = this.items.slice(0);
            this.items = e.concat(i),
            this._resetLayout(),
            this._manageStamps(),
            this.layoutItems(e, !0),
            this.reveal(e),
            this.layoutItems(i)
        }
    }
    ,
    d.reveal = function(t) {
        if (this._emitCompleteOnItems("reveal", t),
        t && t.length) {
            var i = this.updateStagger();
            t.forEach(function(t, e) {
                t.stagger(e * i),
                t.reveal()
            })
        }
    }
    ,
    d.hide = function(t) {
        if (this._emitCompleteOnItems("hide", t),
        t && t.length) {
            var i = this.updateStagger();
            t.forEach(function(t, e) {
                t.stagger(e * i),
                t.hide()
            })
        }
    }
    ,
    d.revealItemElements = function(t) {
        var e = this.getItems(t);
        this.reveal(e)
    }
    ,
    d.hideItemElements = function(t) {
        var e = this.getItems(t);
        this.hide(e)
    }
    ,
    d.getItem = function(t) {
        for (var e = 0; e < this.items.length; e++) {
            var i = this.items[e];
            if (i.element == t)
                return i
        }
    }
    ,
    d.getItems = function(t) {
        t = r.makeArray(t);
        var i = [];
        return t.forEach(function(t) {
            var e = this.getItem(t);
            e && i.push(e)
        }, this),
        i
    }
    ,
    d.remove = function(t) {
        var e = this.getItems(t);
        this._emitCompleteOnItems("remove", e),
        e && e.length && e.forEach(function(t) {
            t.remove(),
            r.removeFrom(this.items, t)
        }, this)
    }
    ,
    d.destroy = function() {
        var t = this.element.style;
        t.height = "",
        t.position = "",
        t.width = "",
        this.items.forEach(function(t) {
            t.destroy()
        }),
        this.unbindResize();
        var e = this.element.outlayerGUID;
        delete c[e],
        delete this.element.outlayerGUID,
        h && h.removeData(this.element, this.constructor.namespace)
    }
    ,
    s.data = function(t) {
        var e = (t = r.getQueryElement(t)) && t.outlayerGUID;
        return e && c[e]
    }
    ,
    s.create = function(t, e) {
        var i = a(s);
        return i.defaults = r.extend({}, s.defaults),
        r.extend(i.defaults, e),
        i.compatOptions = r.extend({}, s.compatOptions),
        i.namespace = t,
        i.data = s.data,
        i.Item = a(n),
        r.htmlInit(i, t),
        h && h.bridget && h.bridget(t, i),
        i
    }
    ;
    var f = {
        ms: 1,
        s: 1e3
    };
    return s.Item = n,
    s
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("isotope/js/item", ["outlayer/outlayer"], e) : "object" == typeof module && module.exports ? module.exports = e(require("outlayer")) : (t.Isotope = t.Isotope || {},
    t.Isotope.Item = e(t.Outlayer))
}(window, function(t) {
    "use strict";
    function e() {
        t.Item.apply(this, arguments)
    }
    var i = e.prototype = Object.create(t.Item.prototype)
      , n = i._create;
    i._create = function() {
        this.id = this.layout.itemGUID++,
        n.call(this),
        this.sortData = {}
    }
    ,
    i.updateSortData = function() {
        if (!this.isIgnored) {
            this.sortData.id = this.id,
            this.sortData["original-order"] = this.id,
            this.sortData.random = Math.random();
            var t = this.layout.options.getSortData
              , e = this.layout._sorters;
            for (var i in t) {
                var n = e[i];
                this.sortData[i] = n(this.element, this)
            }
        }
    }
    ;
    var o = i.destroy;
    return i.destroy = function() {
        o.apply(this, arguments),
        this.css({
            display: ""
        })
    }
    ,
    e
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("isotope/js/layout-mode", ["get-size/get-size", "outlayer/outlayer"], e) : "object" == typeof module && module.exports ? module.exports = e(require("get-size"), require("outlayer")) : (t.Isotope = t.Isotope || {},
    t.Isotope.LayoutMode = e(t.getSize, t.Outlayer))
}(window, function(e, i) {
    "use strict";
    function n(t) {
        (this.isotope = t) && (this.options = t.options[this.namespace],
        this.element = t.element,
        this.items = t.filteredItems,
        this.size = t.size)
    }
    var o = n.prototype;
    return ["_resetLayout", "_getItemLayoutPosition", "_manageStamp", "_getContainerSize", "_getElementOffset", "needsResizeLayout", "_getOption"].forEach(function(t) {
        o[t] = function() {
            return i.prototype[t].apply(this.isotope, arguments)
        }
    }),
    o.needsVerticalResizeLayout = function() {
        var t = e(this.isotope.element);
        return this.isotope.size && t && t.innerHeight != this.isotope.size.innerHeight
    }
    ,
    o._getMeasurement = function() {
        this.isotope._getMeasurement.apply(this, arguments)
    }
    ,
    o.getColumnWidth = function() {
        this.getSegmentSize("column", "Width")
    }
    ,
    o.getRowHeight = function() {
        this.getSegmentSize("row", "Height")
    }
    ,
    o.getSegmentSize = function(t, e) {
        var i = t + e
          , n = "outer" + e;
        if (this._getMeasurement(i, n),
        !this[i]) {
            var o = this.getFirstItemSize();
            this[i] = o && o[n] || this.isotope.size["inner" + e]
        }
    }
    ,
    o.getFirstItemSize = function() {
        var t = this.isotope.filteredItems[0];
        return t && t.element && e(t.element)
    }
    ,
    o.layout = function() {
        this.isotope.layout.apply(this.isotope, arguments)
    }
    ,
    o.getSize = function() {
        this.isotope.getSize(),
        this.size = this.isotope.size
    }
    ,
    n.modes = {},
    n.create = function(t, e) {
        function i() {
            n.apply(this, arguments)
        }
        return (i.prototype = Object.create(o)).constructor = i,
        e && (i.options = e),
        n.modes[i.prototype.namespace = t] = i
    }
    ,
    n
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("masonry/masonry", ["outlayer/outlayer", "get-size/get-size"], e) : "object" == typeof module && module.exports ? module.exports = e(require("outlayer"), require("get-size")) : t.Masonry = e(t.Outlayer, t.getSize)
}(window, function(t, h) {
    var e = t.create("masonry");
    return e.compatOptions.fitWidth = "isFitWidth",
    e.prototype._resetLayout = function() {
        this.getSize(),
        this._getMeasurement("columnWidth", "outerWidth"),
        this._getMeasurement("gutter", "outerWidth"),
        this.measureColumns(),
        this.colYs = [];
        for (var t = 0; t < this.cols; t++)
            this.colYs.push(0);
        this.maxY = 0
    }
    ,
    e.prototype.measureColumns = function() {
        if (this.getContainerWidth(),
        !this.columnWidth) {
            var t = this.items[0]
              , e = t && t.element;
            this.columnWidth = e && h(e).outerWidth || this.containerWidth
        }
        var i = this.columnWidth += this.gutter
          , n = this.containerWidth + this.gutter
          , o = n / i
          , r = i - n % i;
        o = Math[r && r < 1 ? "round" : "floor"](o),
        this.cols = Math.max(o, 1)
    }
    ,
    e.prototype.getContainerWidth = function() {
        var t = this._getOption("fitWidth") ? this.element.parentNode : this.element
          , e = h(t);
        this.containerWidth = e && e.innerWidth
    }
    ,
    e.prototype._getItemLayoutPosition = function(t) {
        t.getSize();
        var e = t.size.outerWidth % this.columnWidth
          , i = Math[e && e < 1 ? "round" : "ceil"](t.size.outerWidth / this.columnWidth);
        i = Math.min(i, this.cols);
        for (var n = this._getColGroup(i), o = Math.min.apply(Math, n), r = n.indexOf(o), s = {
            x: this.columnWidth * r,
            y: o
        }, a = o + t.size.outerHeight, u = this.cols + 1 - n.length, h = 0; h < u; h++)
            this.colYs[r + h] = a;
        return s
    }
    ,
    e.prototype._getColGroup = function(t) {
        if (t < 2)
            return this.colYs;
        for (var e = [], i = this.cols + 1 - t, n = 0; n < i; n++) {
            var o = this.colYs.slice(n, n + t);
            e[n] = Math.max.apply(Math, o)
        }
        return e
    }
    ,
    e.prototype._manageStamp = function(t) {
        var e = h(t)
          , i = this._getElementOffset(t)
          , n = this._getOption("originLeft") ? i.left : i.right
          , o = n + e.outerWidth
          , r = Math.floor(n / this.columnWidth);
        r = Math.max(0, r);
        var s = Math.floor(o / this.columnWidth);
        s -= o % this.columnWidth ? 0 : 1,
        s = Math.min(this.cols - 1, s);
        for (var a = (this._getOption("originTop") ? i.top : i.bottom) + e.outerHeight, u = r; u <= s; u++)
            this.colYs[u] = Math.max(a, this.colYs[u])
    }
    ,
    e.prototype._getContainerSize = function() {
        this.maxY = Math.max.apply(Math, this.colYs);
        var t = {
            height: this.maxY
        };
        return this._getOption("fitWidth") && (t.width = this._getContainerFitWidth()),
        t
    }
    ,
    e.prototype._getContainerFitWidth = function() {
        for (var t = 0, e = this.cols; --e && 0 === this.colYs[e]; )
            t++;
        return (this.cols - t) * this.columnWidth - this.gutter
    }
    ,
    e.prototype.needsResizeLayout = function() {
        var t = this.containerWidth;
        return this.getContainerWidth(),
        t != this.containerWidth
    }
    ,
    e
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("isotope/js/layout-modes/masonry", ["../layout-mode", "masonry/masonry"], e) : "object" == typeof module && module.exports ? module.exports = e(require("../layout-mode"), require("masonry-layout")) : e(t.Isotope.LayoutMode, t.Masonry)
}(window, function(t, e) {
    "use strict";
    var i = t.create("masonry")
      , n = i.prototype
      , o = {
        _getElementOffset: !0,
        layout: !0,
        _getMeasurement: !0
    };
    for (var r in e.prototype)
        o[r] || (n[r] = e.prototype[r]);
    var s = n.measureColumns;
    n.measureColumns = function() {
        this.items = this.isotope.filteredItems,
        s.call(this)
    }
    ;
    var a = n._getOption;
    return n._getOption = function(t) {
        return "fitWidth" == t ? void 0 !== this.options.isFitWidth ? this.options.isFitWidth : this.options.fitWidth : a.apply(this.isotope, arguments)
    }
    ,
    i
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("isotope/js/layout-modes/fit-rows", ["../layout-mode"], e) : "object" == typeof exports ? module.exports = e(require("../layout-mode")) : e(t.Isotope.LayoutMode)
}(window, function(t) {
    "use strict";
    var e = t.create("fitRows")
      , i = e.prototype;
    return i._resetLayout = function() {
        this.x = 0,
        this.y = 0,
        this.maxY = 0,
        this._getMeasurement("gutter", "outerWidth")
    }
    ,
    i._getItemLayoutPosition = function(t) {
        t.getSize();
        var e = t.size.outerWidth + this.gutter
          , i = this.isotope.size.innerWidth + this.gutter;
        0 !== this.x && e + this.x > i && (this.x = 0,
        this.y = this.maxY);
        var n = {
            x: this.x,
            y: this.y
        };
        return this.maxY = Math.max(this.maxY, this.y + t.size.outerHeight),
        this.x += e,
        n
    }
    ,
    i._getContainerSize = function() {
        return {
            height: this.maxY
        }
    }
    ,
    e
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("isotope/js/layout-modes/vertical", ["../layout-mode"], e) : "object" == typeof module && module.exports ? module.exports = e(require("../layout-mode")) : e(t.Isotope.LayoutMode)
}(window, function(t) {
    "use strict";
    var e = t.create("vertical", {
        horizontalAlignment: 0
    })
      , i = e.prototype;
    return i._resetLayout = function() {
        this.y = 0
    }
    ,
    i._getItemLayoutPosition = function(t) {
        t.getSize();
        var e = (this.isotope.size.innerWidth - t.size.outerWidth) * this.options.horizontalAlignment
          , i = this.y;
        return this.y += t.size.outerHeight,
        {
            x: e,
            y: i
        }
    }
    ,
    i._getContainerSize = function() {
        return {
            height: this.y
        }
    }
    ,
    e
}),
function(s, a) {
    "function" == typeof define && define.amd ? define(["outlayer/outlayer", "get-size/get-size", "desandro-matches-selector/matches-selector", "fizzy-ui-utils/utils", "isotope/js/item", "isotope/js/layout-mode", "isotope/js/layout-modes/masonry", "isotope/js/layout-modes/fit-rows", "isotope/js/layout-modes/vertical"], function(t, e, i, n, o, r) {
        return a(s, t, e, i, n, o, r)
    }) : "object" == typeof module && module.exports ? module.exports = a(s, require("outlayer"), require("get-size"), require("desandro-matches-selector"), require("fizzy-ui-utils"), require("isotope/js/item"), require("isotope/js/layout-mode"), require("isotope/js/layout-modes/masonry"), require("isotope/js/layout-modes/fit-rows"), require("isotope/js/layout-modes/vertical")) : s.Isotope = a(s, s.Outlayer, s.getSize, s.matchesSelector, s.fizzyUIUtils, s.Isotope.Item, s.Isotope.LayoutMode)
}(window, function(t, i, e, n, r, o, s) {
    var a = t.jQuery
      , h = String.prototype.trim ? function(t) {
        return t.trim()
    }
    : function(t) {
        return t.replace(/^\s+|\s+$/g, "")
    }
      , l = i.create("isotope", {
        layoutMode: "masonry",
        isJQueryFiltering: !0,
        sortAscending: !0
    });
    l.Item = o,
    l.LayoutMode = s;
    var u = l.prototype;
    u._create = function() {
        for (var t in this.itemGUID = 0,
        this._sorters = {},
        this._getSorters(),
        i.prototype._create.call(this),
        this.modes = {},
        this.filteredItems = this.items,
        this.sortHistory = ["original-order"],
        s.modes)
            this._initLayoutMode(t)
    }
    ,
    u.reloadItems = function() {
        this.itemGUID = 0,
        i.prototype.reloadItems.call(this)
    }
    ,
    u._itemize = function() {
        for (var t = i.prototype._itemize.apply(this, arguments), e = 0; e < t.length; e++) {
            t[e].id = this.itemGUID++
        }
        return this._updateItemsSortData(t),
        t
    }
    ,
    u._initLayoutMode = function(t) {
        var e = s.modes[t]
          , i = this.options[t] || {};
        this.options[t] = e.options ? r.extend(e.options, i) : i,
        this.modes[t] = new e(this)
    }
    ,
    u.layout = function() {
        return !this._isLayoutInited && this._getOption("initLayout") ? void this.arrange() : void this._layout()
    }
    ,
    u._layout = function() {
        var t = this._getIsInstant();
        this._resetLayout(),
        this._manageStamps(),
        this.layoutItems(this.filteredItems, t),
        this._isLayoutInited = !0
    }
    ,
    u.arrange = function(t) {
        this.option(t),
        this._getIsInstant();
        var e = this._filter(this.items);
        this.filteredItems = e.matches,
        this._bindArrangeComplete(),
        this._isInstant ? this._noTransition(this._hideReveal, [e]) : this._hideReveal(e),
        this._sort(),
        this._layout()
    }
    ,
    u._init = u.arrange,
    u._hideReveal = function(t) {
        this.reveal(t.needReveal),
        this.hide(t.needHide)
    }
    ,
    u._getIsInstant = function() {
        var t = this._getOption("layoutInstant")
          , e = void 0 !== t ? t : !this._isLayoutInited;
        return this._isInstant = e
    }
    ,
    u._bindArrangeComplete = function() {
        function t() {
            e && i && n && o.dispatchEvent("arrangeComplete", null, [o.filteredItems])
        }
        var e, i, n, o = this;
        this.once("layoutComplete", function() {
            e = !0,
            t()
        }),
        this.once("hideComplete", function() {
            i = !0,
            t()
        }),
        this.once("revealComplete", function() {
            n = !0,
            t()
        })
    }
    ,
    u._filter = function(t) {
        var e = this.options.filter;
        e = e || "*";
        for (var i = [], n = [], o = [], r = this._getFilterTest(e), s = 0; s < t.length; s++) {
            var a = t[s];
            if (!a.isIgnored) {
                var u = r(a);
                u && i.push(a),
                u && a.isHidden ? n.push(a) : u || a.isHidden || o.push(a)
            }
        }
        return {
            matches: i,
            needReveal: n,
            needHide: o
        }
    }
    ,
    u._getFilterTest = function(e) {
        return a && this.options.isJQueryFiltering ? function(t) {
            return a(t.element).is(e)
        }
        : "function" == typeof e ? function(t) {
            return e(t.element)
        }
        : function(t) {
            return n(t.element, e)
        }
    }
    ,
    u.updateSortData = function(t) {
        var e;
        t ? (t = r.makeArray(t),
        e = this.getItems(t)) : e = this.items,
        this._getSorters(),
        this._updateItemsSortData(e)
    }
    ,
    u._getSorters = function() {
        var t = this.options.getSortData;
        for (var e in t) {
            var i = t[e];
            this._sorters[e] = c(i)
        }
    }
    ,
    u._updateItemsSortData = function(t) {
        for (var e = t && t.length, i = 0; e && i < e; i++) {
            t[i].updateSortData()
        }
    }
    ;
    var c = function(t) {
        if ("string" != typeof t)
            return t;
        var e, i, n = h(t).split(" "), o = n[0], r = o.match(/^\[(.+)\]$/), s = r && r[1], a = (i = o,
        (e = s) ? function(t) {
            return t.getAttribute(e)
        }
        : function(t) {
            var e = t.querySelector(i);
            return e && e.textContent
        }
        ), u = l.sortDataParsers[n[1]];
        return u ? function(t) {
            return t && u(a(t))
        }
        : function(t) {
            return t && a(t)
        }
    };
    l.sortDataParsers = {
        parseInt: function(t) {
            return parseInt(t, 10)
        },
        parseFloat: function(t) {
            return parseFloat(t)
        }
    },
    u._sort = function() {
        var s, a, t = this.options.sortBy;
        if (t) {
            var e = [].concat.apply(t, this.sortHistory)
              , i = (s = e,
            a = this.options.sortAscending,
            function(t, e) {
                for (var i = 0; i < s.length; i++) {
                    var n = s[i]
                      , o = t.sortData[n]
                      , r = e.sortData[n];
                    if (r < o || o < r)
                        return (r < o ? 1 : -1) * ((void 0 !== a[n] ? a[n] : a) ? 1 : -1)
                }
                return 0
            }
            );
            this.filteredItems.sort(i),
            t != this.sortHistory[0] && this.sortHistory.unshift(t)
        }
    }
    ,
    u._mode = function() {
        var t = this.options.layoutMode
          , e = this.modes[t];
        if (!e)
            throw new Error("No layout mode: " + t);
        return e.options = this.options[t],
        e
    }
    ,
    u._resetLayout = function() {
        i.prototype._resetLayout.call(this),
        this._mode()._resetLayout()
    }
    ,
    u._getItemLayoutPosition = function(t) {
        return this._mode()._getItemLayoutPosition(t)
    }
    ,
    u._manageStamp = function(t) {
        this._mode()._manageStamp(t)
    }
    ,
    u._getContainerSize = function() {
        return this._mode()._getContainerSize()
    }
    ,
    u.needsResizeLayout = function() {
        return this._mode().needsResizeLayout()
    }
    ,
    u.appended = function(t) {
        var e = this.addItems(t);
        if (e.length) {
            var i = this._filterRevealAdded(e);
            this.filteredItems = this.filteredItems.concat(i)
        }
    }
    ,
    u.prepended = function(t) {
        var e = this._itemize(t);
        if (e.length) {
            this._resetLayout(),
            this._manageStamps();
            var i = this._filterRevealAdded(e);
            this.layoutItems(this.filteredItems),
            this.filteredItems = i.concat(this.filteredItems),
            this.items = e.concat(this.items)
        }
    }
    ,
    u._filterRevealAdded = function(t) {
        var e = this._filter(t);
        return this.hide(e.needHide),
        this.reveal(e.matches),
        this.layoutItems(e.matches, !0),
        e.matches
    }
    ,
    u.insert = function(t) {
        var e = this.addItems(t);
        if (e.length) {
            var i, n, o = e.length;
            for (i = 0; i < o; i++)
                n = e[i],
                this.element.appendChild(n.element);
            var r = this._filter(e).matches;
            for (i = 0; i < o; i++)
                e[i].isLayoutInstant = !0;
            for (this.arrange(),
            i = 0; i < o; i++)
                delete e[i].isLayoutInstant;
            this.reveal(r)
        }
    }
    ;
    var d = u.remove;
    return u.remove = function(t) {
        t = r.makeArray(t);
        var e = this.getItems(t);
        d.call(this, t);
        for (var i = e && e.length, n = 0; i && n < i; n++) {
            var o = e[n];
            r.removeFrom(this.filteredItems, o)
        }
    }
    ,
    u.shuffle = function() {
        for (var t = 0; t < this.items.length; t++) {
            this.items[t].sortData.random = Math.random()
        }
        this.options.sortBy = "random",
        this._sort(),
        this._layout()
    }
    ,
    u._noTransition = function(t, e) {
        var i = this.options.transitionDuration;
        this.options.transitionDuration = 0;
        var n = t.apply(this, e);
        return this.options.transitionDuration = i,
        n
    }
    ,
    u.getFilteredItemElements = function() {
        return this.filteredItems.map(function(t) {
            return t.element
        })
    }
    ,
    l
}),
function(t) {
    var e = !1;
    if ("function" == typeof define && define.amd && (define(t),
    e = !0),
    "object" == typeof exports && (module.exports = t(),
    e = !0),
    !e) {
        var i = window.Cookies
          , n = window.Cookies = t();
        n.noConflict = function() {
            return window.Cookies = i,
            n
        }
    }
}(function() {
    function f() {
        for (var t = 0, e = {}; t < arguments.length; t++) {
            var i = arguments[t];
            for (var n in i)
                e[n] = i[n]
        }
        return e
    }
    return function t(c) {
        function d(t, e, i) {
            var n;
            if ("undefined" != typeof document) {
                if (1 < arguments.length) {
                    if ("number" == typeof (i = f({
                        path: "/"
                    }, d.defaults, i)).expires) {
                        var o = new Date;
                        o.setMilliseconds(o.getMilliseconds() + 864e5 * i.expires),
                        i.expires = o
                    }
                    try {
                        n = JSON.stringify(e),
                        /^[\{\[]/.test(n) && (e = n)
                    } catch (t) {}
                    return e = c.write ? c.write(e, t) : encodeURIComponent(String(e)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent),
                    t = (t = (t = encodeURIComponent(String(t))).replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)).replace(/[\(\)]/g, escape),
                    document.cookie = [t, "=", e, i.expires ? "; expires=" + i.expires.toUTCString() : "", i.path ? "; path=" + i.path : "", i.domain ? "; domain=" + i.domain : "", i.secure ? "; secure" : ""].join("")
                }
                t || (n = {});
                for (var r = document.cookie ? document.cookie.split("; ") : [], s = /(%[0-9A-Z]{2})+/g, a = 0; a < r.length; a++) {
                    var u = r[a].split("=")
                      , h = u.slice(1).join("=");
                    '"' === h.charAt(0) && (h = h.slice(1, -1));
                    try {
                        var l = u[0].replace(s, decodeURIComponent);
                        if (h = c.read ? c.read(h, l) : c(h, l) || h.replace(s, decodeURIComponent),
                        this.json)
                            try {
                                h = JSON.parse(h)
                            } catch (t) {}
                        if (t === l) {
                            n = h;
                            break
                        }
                        t || (n[l] = h)
                    } catch (t) {}
                }
                return n
            }
        }
        return (d.set = d).get = function(t) {
            return d.call(d, t)
        }
        ,
        d.getJSON = function() {
            return d.apply({
                json: !0
            }, [].slice.call(arguments))
        }
        ,
        d.defaults = {},
        d.remove = function(t, e) {
            d(t, "", f(e, {
                expires: -1
            }))
        }
        ,
        d.withConverter = t,
        d
    }(function() {})
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("packery/js/rect", e) : "object" == typeof module && module.exports ? module.exports = e() : (t.Packery = t.Packery || {},
    t.Packery.Rect = e())
}(window, function() {
    function a(t) {
        for (var e in a.defaults)
            this[e] = a.defaults[e];
        for (e in t)
            this[e] = t[e]
    }
    a.defaults = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    };
    var t = a.prototype;
    return t.contains = function(t) {
        var e = t.width || 0
          , i = t.height || 0;
        return this.x <= t.x && this.y <= t.y && this.x + this.width >= t.x + e && this.y + this.height >= t.y + i
    }
    ,
    t.overlaps = function(t) {
        var e = this.x + this.width
          , i = this.y + this.height
          , n = t.x + t.width
          , o = t.y + t.height;
        return this.x < n && e > t.x && this.y < o && i > t.y
    }
    ,
    t.getMaximalFreeRects = function(t) {
        if (!this.overlaps(t))
            return !1;
        var e, i = [], n = this.x + this.width, o = this.y + this.height, r = t.x + t.width, s = t.y + t.height;
        return this.y < t.y && (e = new a({
            x: this.x,
            y: this.y,
            width: this.width,
            height: t.y - this.y
        }),
        i.push(e)),
        r < n && (e = new a({
            x: r,
            y: this.y,
            width: n - r,
            height: this.height
        }),
        i.push(e)),
        s < o && (e = new a({
            x: this.x,
            y: s,
            width: this.width,
            height: o - s
        }),
        i.push(e)),
        this.x < t.x && (e = new a({
            x: this.x,
            y: this.y,
            width: t.x - this.x,
            height: this.height
        }),
        i.push(e)),
        i
    }
    ,
    t.canFit = function(t) {
        return this.width >= t.width && this.height >= t.height
    }
    ,
    a
}),
function(t, e) {
    if ("function" == typeof define && define.amd)
        define("packery/js/packer", ["./rect"], e);
    else if ("object" == typeof module && module.exports)
        module.exports = e(require("./rect"));
    else {
        var i = t.Packery = t.Packery || {};
        i.Packer = e(i.Rect)
    }
}(window, function(e) {
    function t(t, e, i) {
        this.width = t || 0,
        this.height = e || 0,
        this.sortDirection = i || "downwardLeftToRight",
        this.reset()
    }
    var i = t.prototype;
    i.reset = function() {
        this.spaces = [];
        var t = new e({
            x: 0,
            y: 0,
            width: this.width,
            height: this.height
        });
        this.spaces.push(t),
        this.sorter = n[this.sortDirection] || n.downwardLeftToRight
    }
    ,
    i.pack = function(t) {
        for (var e = 0; e < this.spaces.length; e++) {
            var i = this.spaces[e];
            if (i.canFit(t)) {
                this.placeInSpace(t, i);
                break
            }
        }
    }
    ,
    i.columnPack = function(t) {
        for (var e = 0; e < this.spaces.length; e++) {
            var i = this.spaces[e];
            if (i.x <= t.x && i.x + i.width >= t.x + t.width && i.height >= t.height - .01) {
                t.y = i.y,
                this.placed(t);
                break
            }
        }
    }
    ,
    i.rowPack = function(t) {
        for (var e = 0; e < this.spaces.length; e++) {
            var i = this.spaces[e];
            if (i.y <= t.y && i.y + i.height >= t.y + t.height && i.width >= t.width - .01) {
                t.x = i.x,
                this.placed(t);
                break
            }
        }
    }
    ,
    i.placeInSpace = function(t, e) {
        t.x = e.x,
        t.y = e.y,
        this.placed(t)
    }
    ,
    i.placed = function(t) {
        for (var e = [], i = 0; i < this.spaces.length; i++) {
            var n = this.spaces[i]
              , o = n.getMaximalFreeRects(t);
            o ? e.push.apply(e, o) : e.push(n)
        }
        this.spaces = e,
        this.mergeSortSpaces()
    }
    ,
    i.mergeSortSpaces = function() {
        t.mergeRects(this.spaces),
        this.spaces.sort(this.sorter)
    }
    ,
    i.addSpace = function(t) {
        this.spaces.push(t),
        this.mergeSortSpaces()
    }
    ,
    t.mergeRects = function(t) {
        var e = 0
          , i = t[e];
        t: for (; i; ) {
            for (var n = 0, o = t[e + n]; o; ) {
                if (o == i)
                    n++;
                else {
                    if (o.contains(i)) {
                        t.splice(e, 1),
                        i = t[e];
                        continue t
                    }
                    i.contains(o) ? t.splice(e + n, 1) : n++
                }
                o = t[e + n]
            }
            i = t[++e]
        }
        return t
    }
    ;
    var n = {
        downwardLeftToRight: function(t, e) {
            return t.y - e.y || t.x - e.x
        },
        rightwardTopToBottom: function(t, e) {
            return t.x - e.x || t.y - e.y
        }
    };
    return t
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("packery/js/item", ["outlayer/outlayer", "./rect"], e) : "object" == typeof module && module.exports ? module.exports = e(require("outlayer"), require("./rect")) : t.Packery.Item = e(t.Outlayer, t.Packery.Rect)
}(window, function(t, e) {
    var i = "string" == typeof document.documentElement.style.transform ? "transform" : "WebkitTransform"
      , n = function() {
        t.Item.apply(this, arguments)
    }
      , o = n.prototype = Object.create(t.Item.prototype)
      , r = o._create;
    o._create = function() {
        r.call(this),
        this.rect = new e
    }
    ;
    var s = o.moveTo;
    return o.moveTo = function(t, e) {
        var i = Math.abs(this.position.x - t)
          , n = Math.abs(this.position.y - e);
        this.layout.dragItemCount && !this.isPlacing && !this.isTransitioning && i < 1 && n < 1 ? this.goTo(t, e) : s.apply(this, arguments)
    }
    ,
    o.enablePlacing = function() {
        this.removeTransitionStyles(),
        this.isTransitioning && i && (this.element.style[i] = "none"),
        this.isTransitioning = !1,
        this.getSize(),
        this.layout._setRectSize(this.element, this.rect),
        this.isPlacing = !0
    }
    ,
    o.disablePlacing = function() {
        this.isPlacing = !1
    }
    ,
    o.removeElem = function() {
        this.element.parentNode.removeChild(this.element),
        this.layout.packer.addSpace(this.rect),
        this.emitEvent("remove", [this])
    }
    ,
    o.showDropPlaceholder = function() {
        var t = this.dropPlaceholder;
        t || ((t = this.dropPlaceholder = document.createElement("div")).className = "packery-drop-placeholder",
        t.style.position = "absolute"),
        t.style.width = this.size.width + "px",
        t.style.height = this.size.height + "px",
        this.positionDropPlaceholder(),
        this.layout.element.appendChild(t)
    }
    ,
    o.positionDropPlaceholder = function() {
        this.dropPlaceholder.style[i] = "translate(" + this.rect.x + "px, " + this.rect.y + "px)"
    }
    ,
    o.hideDropPlaceholder = function() {
        this.layout.element.removeChild(this.dropPlaceholder)
    }
    ,
    n
}),
function(t, e) {
    "function" == typeof define && define.amd ? define("packery/js/packery", ["get-size/get-size", "outlayer/outlayer", "./rect", "./packer", "./item"], e) : "object" == typeof module && module.exports ? module.exports = e(require("get-size"), require("outlayer"), require("./rect"), require("./packer"), require("./item")) : t.Packery = e(t.getSize, t.Outlayer, t.Packery.Rect, t.Packery.Packer, t.Packery.Item)
}(window, function(l, t, f, e, i) {
    f.prototype.canFit = function(t) {
        return this.width >= t.width - 1 && this.height >= t.height - 1
    }
    ;
    var n = t.create("packery");
    n.Item = i;
    var o = n.prototype;
    function r(t, e) {
        return t.position.y - e.position.y || t.position.x - e.position.x
    }
    function s(t, e) {
        return t.position.x - e.position.x || t.position.y - e.position.y
    }
    o._create = function() {
        t.prototype._create.call(this),
        this.packer = new e,
        this.shiftPacker = new e,
        this.isEnabled = !0,
        this.dragItemCount = 0;
        var i = this;
        this.handleDraggabilly = {
            dragStart: function() {
                i.itemDragStart(this.element)
            },
            dragMove: function() {
                i.itemDragMove(this.element, this.position.x, this.position.y)
            },
            dragEnd: function() {
                i.itemDragEnd(this.element)
            }
        },
        this.handleUIDraggable = {
            start: function(t, e) {
                e && i.itemDragStart(t.currentTarget)
            },
            drag: function(t, e) {
                e && i.itemDragMove(t.currentTarget, e.position.left, e.position.top)
            },
            stop: function(t, e) {
                e && i.itemDragEnd(t.currentTarget)
            }
        }
    }
    ,
    o._resetLayout = function() {
        var t, e, i;
        this.getSize(),
        this._getMeasurements(),
        this._getOption("horizontal") ? (t = 1 / 0,
        e = this.size.innerHeight + this.gutter,
        i = "rightwardTopToBottom") : (t = this.size.innerWidth + this.gutter,
        e = 1 / 0,
        i = "downwardLeftToRight"),
        this.packer.width = this.shiftPacker.width = t,
        this.packer.height = this.shiftPacker.height = e,
        this.packer.sortDirection = this.shiftPacker.sortDirection = i,
        this.packer.reset(),
        this.maxY = 0,
        this.maxX = 0
    }
    ,
    o._getMeasurements = function() {
        this._getMeasurement("columnWidth", "width"),
        this._getMeasurement("rowHeight", "height"),
        this._getMeasurement("gutter", "width")
    }
    ,
    o._getItemLayoutPosition = function(t) {
        if (this._setRectSize(t.element, t.rect),
        this.isShifting || 0 < this.dragItemCount) {
            var e = this._getPackMethod();
            this.packer[e](t.rect)
        } else
            this.packer.pack(t.rect);
        return this._setMaxXY(t.rect),
        t.rect
    }
    ,
    o.shiftLayout = function() {
        this.isShifting = !0,
        this.layout(),
        delete this.isShifting
    }
    ,
    o._getPackMethod = function() {
        return this._getOption("horizontal") ? "rowPack" : "columnPack"
    }
    ,
    o._setMaxXY = function(t) {
        this.maxX = Math.max(t.x + t.width, this.maxX),
        this.maxY = Math.max(t.y + t.height, this.maxY)
    }
    ,
    o._setRectSize = function(t, e) {
        var i = l(t)
          , n = i.outerWidth
          , o = i.outerHeight;
        (n || o) && (n = this._applyGridGutter(n, this.columnWidth),
        o = this._applyGridGutter(o, this.rowHeight)),
        e.width = Math.min(n, this.packer.width),
        e.height = Math.min(o, this.packer.height)
    }
    ,
    o._applyGridGutter = function(t, e) {
        if (!e)
            return t + this.gutter;
        var i = t % (e += this.gutter);
        return t = Math[i && i < 1 ? "round" : "ceil"](t / e) * e
    }
    ,
    o._getContainerSize = function() {
        return this._getOption("horizontal") ? {
            width: this.maxX - this.gutter
        } : {
            height: this.maxY - this.gutter
        }
    }
    ,
    o._manageStamp = function(t) {
        var e, i = this.getItem(t);
        if (i && i.isPlacing)
            e = i.rect;
        else {
            var n = this._getElementOffset(t);
            e = new f({
                x: this._getOption("originLeft") ? n.left : n.right,
                y: this._getOption("originTop") ? n.top : n.bottom
            })
        }
        this._setRectSize(t, e),
        this.packer.placed(e),
        this._setMaxXY(e)
    }
    ,
    o.sortItemsByPosition = function() {
        var t = this._getOption("horizontal") ? s : r;
        this.items.sort(t)
    }
    ,
    o.fit = function(t, e, i) {
        var n = this.getItem(t);
        n && (this.stamp(n.element),
        n.enablePlacing(),
        this.updateShiftTargets(n),
        e = void 0 === e ? n.rect.x : e,
        i = void 0 === i ? n.rect.y : i,
        this.shift(n, e, i),
        this._bindFitEvents(n),
        n.moveTo(n.rect.x, n.rect.y),
        this.shiftLayout(),
        this.unstamp(n.element),
        this.sortItemsByPosition(),
        n.disablePlacing())
    }
    ,
    o._bindFitEvents = function(t) {
        var e = this
          , i = 0;
        function n() {
            2 == ++i && e.dispatchEvent("fitComplete", null, [t])
        }
        t.once("layout", n),
        this.once("layoutComplete", n)
    }
    ,
    o.resize = function() {
        this.isResizeBound && this.needsResizeLayout() && (this.options.shiftPercentResize ? this.resizeShiftPercentLayout() : this.layout())
    }
    ,
    o.needsResizeLayout = function() {
        var t = l(this.element)
          , e = this._getOption("horizontal") ? "innerHeight" : "innerWidth";
        return t[e] != this.size[e]
    }
    ,
    o.resizeShiftPercentLayout = function() {
        var t = this._getItemsForLayout(this.items)
          , e = this._getOption("horizontal")
          , i = e ? "y" : "x"
          , n = e ? "height" : "width"
          , o = e ? "rowHeight" : "columnWidth"
          , r = e ? "innerHeight" : "innerWidth"
          , s = this[o];
        if (s = s && s + this.gutter) {
            this._getMeasurements();
            var a = this[o] + this.gutter;
            t.forEach(function(t) {
                var e = Math.round(t.rect[i] / s);
                t.rect[i] = e * a
            })
        } else {
            var u = l(this.element)[r] + this.gutter
              , h = this.packer[n];
            t.forEach(function(t) {
                t.rect[i] = t.rect[i] / h * u
            })
        }
        this.shiftLayout()
    }
    ,
    o.itemDragStart = function(t) {
        if (this.isEnabled) {
            this.stamp(t);
            var e = this.getItem(t);
            e && (e.enablePlacing(),
            e.showDropPlaceholder(),
            this.dragItemCount++,
            this.updateShiftTargets(e))
        }
    }
    ,
    o.updateShiftTargets = function(t) {
        this.shiftPacker.reset(),
        this._getBoundingRect();
        var o = this._getOption("originLeft")
          , r = this._getOption("originTop");
        this.stamps.forEach(function(t) {
            var e = this.getItem(t);
            if (!e || !e.isPlacing) {
                var i = this._getElementOffset(t)
                  , n = new f({
                    x: o ? i.left : i.right,
                    y: r ? i.top : i.bottom
                });
                this._setRectSize(t, n),
                this.shiftPacker.placed(n)
            }
        }, this);
        var u, h = this._getOption("horizontal"), e = h ? "rowHeight" : "columnWidth", l = h ? "height" : "width";
        this.shiftTargetKeys = [],
        this.shiftTargets = [];
        var c = this[e];
        if (c = c && c + this.gutter) {
            var i = Math.ceil(t.rect[l] / c)
              , n = Math.floor((this.shiftPacker[l] + this.gutter) / c);
            u = (n - i) * c;
            for (var s = 0; s < n; s++)
                this._addShiftTarget(s * c, 0, u)
        } else
            u = this.shiftPacker[l] + this.gutter - t.rect[l],
            this._addShiftTarget(0, 0, u);
        var a = this._getItemsForLayout(this.items)
          , d = this._getPackMethod();
        a.forEach(function(t) {
            var e = t.rect;
            this._setRectSize(t.element, e),
            this.shiftPacker[d](e),
            this._addShiftTarget(e.x, e.y, u);
            var i = h ? e.x + e.width : e.x
              , n = h ? e.y : e.y + e.height;
            if (this._addShiftTarget(i, n, u),
            c)
                for (var o = Math.round(e[l] / c), r = 1; r < o; r++) {
                    var s = h ? i : e.x + c * r
                      , a = h ? e.y + c * r : n;
                    this._addShiftTarget(s, a, u)
                }
        }, this)
    }
    ,
    o._addShiftTarget = function(t, e, i) {
        var n = this._getOption("horizontal") ? e : t;
        if (!(0 !== n && i < n)) {
            var o = t + "," + e;
            -1 != this.shiftTargetKeys.indexOf(o) || (this.shiftTargetKeys.push(o),
            this.shiftTargets.push({
                x: t,
                y: e
            }))
        }
    }
    ,
    o.shift = function(t, e, i) {
        var s, a = 1 / 0, u = {
            x: e,
            y: i
        };
        this.shiftTargets.forEach(function(t) {
            var e, i, n, o, r = (n = (i = u).x - (e = t).x,
            o = i.y - e.y,
            Math.sqrt(n * n + o * o));
            r < a && (s = t,
            a = r)
        }),
        t.rect.x = s.x,
        t.rect.y = s.y
    }
    ;
    o.itemDragMove = function(t, e, i) {
        var n = this.isEnabled && this.getItem(t);
        if (n) {
            e -= this.size.paddingLeft,
            i -= this.size.paddingTop;
            var o = this
              , r = new Date;
            this._itemDragTime && r - this._itemDragTime < 120 ? (clearTimeout(this.dragTimeout),
            this.dragTimeout = setTimeout(s, 120)) : (s(),
            this._itemDragTime = r)
        }
        function s() {
            o.shift(n, e, i),
            n.positionDropPlaceholder(),
            o.layout()
        }
    }
    ,
    o.itemDragEnd = function(t) {
        var e = this.isEnabled && this.getItem(t);
        if (e) {
            clearTimeout(this.dragTimeout),
            e.element.classList.add("is-positioning-post-drag");
            var i = 0
              , n = this;
            e.once("layout", o),
            this.once("layoutComplete", o),
            e.moveTo(e.rect.x, e.rect.y),
            this.layout(),
            this.dragItemCount = Math.max(0, this.dragItemCount - 1),
            this.sortItemsByPosition(),
            e.disablePlacing(),
            this.unstamp(e.element)
        }
        function o() {
            2 == ++i && (e.element.classList.remove("is-positioning-post-drag"),
            e.hideDropPlaceholder(),
            n.dispatchEvent("dragItemPositioned", null, [e]))
        }
    }
    ,
    o.bindDraggabillyEvents = function(t) {
        this._bindDraggabillyEvents(t, "on")
    }
    ,
    o.unbindDraggabillyEvents = function(t) {
        this._bindDraggabillyEvents(t, "off")
    }
    ,
    o._bindDraggabillyEvents = function(t, e) {
        var i = this.handleDraggabilly;
        t[e]("dragStart", i.dragStart),
        t[e]("dragMove", i.dragMove),
        t[e]("dragEnd", i.dragEnd)
    }
    ,
    o.bindUIDraggableEvents = function(t) {
        this._bindUIDraggableEvents(t, "on")
    }
    ,
    o.unbindUIDraggableEvents = function(t) {
        this._bindUIDraggableEvents(t, "off")
    }
    ,
    o._bindUIDraggableEvents = function(t, e) {
        var i = this.handleUIDraggable;
        t[e]("dragstart", i.start)[e]("drag", i.drag)[e]("dragstop", i.stop)
    }
    ;
    var a = o.destroy;
    return o.destroy = function() {
        a.apply(this, arguments),
        this.isEnabled = !1
    }
    ,
    n.Rect = f,
    n.Packer = e,
    n
}),
function(t, e) {
    "function" == typeof define && define.amd ? define(["isotope/js/layout-mode", "packery/js/packery"], e) : "object" == typeof module && module.exports ? module.exports = e(require("isotope-layout/js/layout-mode"), require("packery")) : e(t.Isotope.LayoutMode, t.Packery)
}(window, function(t, e) {
    var i = t.create("packery")
      , n = i.prototype
      , o = {
        _getElementOffset: !0,
        _getMeasurement: !0
    };
    for (var r in e.prototype)
        o[r] || (n[r] = e.prototype[r]);
    var s = n._resetLayout;
    n._resetLayout = function() {
        this.packer = this.packer || new e.Packer,
        this.shiftPacker = this.shiftPacker || new e.Packer,
        s.apply(this, arguments)
    }
    ;
    var a = n._getItemLayoutPosition;
    n._getItemLayoutPosition = function(t) {
        return t.rect = t.rect || new e.Rect,
        a.call(this, t)
    }
    ;
    var u = n.needsResizeLayout;
    n.needsResizeLayout = function() {
        return this._getOption("horizontal") ? this.needsVerticalResizeLayout() : u.call(this)
    }
    ;
    var h = n._getOption;
    return n._getOption = function(t) {
        return "horizontal" == t ? void 0 !== this.options.isHorizontal ? this.options.isHorizontal : this.options.horizontal : h.apply(this.isotope, arguments)
    }
    ,
    i
});
!function() {
    "use strict";
    var e, t = 620, i = $(window), a = $(".ShopTheLooks__slides");
    function r() {
        a.not(".slick-initialized").slick({
            mobileFirst: !0,
            arrows: !0,
            dots: !0,
            appendDots: ".ShopTheLooks",
            adaptiveHeight: !1,
            responsive: [{
                breakpoint: t,
                settings: "unslick"
            }]
        })
    }
    function l() {
        i.outerWidth() < t + 1 ? (r(),
        e = $(".lookPhoto_slide").height(),
        $(".product_slide").css("min-height", e)) : $(".product_slide").css("min-height", 0)
    }
    $(window).on("load", function() {
        a.on("breakpoint", function(e, t, a) {
            i.on("resize", l)
        }),
        i.outerWidth() > t ? i.on("resize", l) : (e = $(".lookPhoto_slide").height(),
        $(".product_slide").css("min-height", e)),
        r()
    })
}(),
function() {
    "use strict";
    $(".RelatedArticles__backToTop").click(function(e) {
        e.preventDefault(),
        $("body").scrollTo(0, 300)
    }),
    $(".RelatedArticles__articleWrapper").on("mouseenter", function() {
        var e = $(this);
        setTimeout(function() {
            e.addClass("hovered")
        }, 350)
    }),
    $(".RelatedArticles__articleWrapper").on("mouseleave", function() {
        $(this).removeClass("hovered")
    })
}(),
function() {
    "use strict";
    var e = 641
      , i = $(window)
      , t = $(".NoResults__carousel");
    function r() {
        i.outerWidth() < e + 1 && (a(),
        i.off("resize", r))
    }
    function a() {
        t.not(".slick-initialized").slick({
            mobileFirst: !0,
            arrows: !0,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
            dots: !0,
            adaptiveHeight: !1,
            fade: !0,
            centerMode: !0,
            responsive: [{
                breakpoint: e,
                settings: "unslick"
            }]
        })
    }
    t.length && (t.on("breakpoint", function(e, t, a) {
        i.on("resize", r)
    }),
    i.outerWidth() > e && i.on("resize", r),
    a()),
    $(".NoResults__carouselProductInside").click(function(e) {}),
    $(document).ready(function() {
        a()
    })
}(),
function() {
    "use strict";
    $(window);
    var e, t = $(".ProductHeader__carousel"), a = $(".ProductHeader__carouselNav");
    function i() {
        var e = 0;
        setTimeout(function() {
            $(".ProductHeader__carouselNavItem").each(function() {
                e += $(this).outerHeight()
            }),
            $(".ProductHeader__carouselNav .slick-track, .ProductHeader__carouselNav .slick-list").css("height", e)
        }, 150)
    }
    function r() {
        if (1 < $(".ProductHeader__carouselNavItem").length) {
            var e = $(".ProductHeader__carouselNavItem.slick-current").find(".ProductHeader__carouselNavItemInside span").html();
            $(".ProductHeader__carouselItemDescription").fadeOut(150),
            setTimeout(function() {
                $(".ProductHeader__carouselItemDescription").empty(),
                $(".ProductHeader__carouselItemDescription").append(e),
                $(".ProductHeader__carouselItemDescription").fadeIn(150)
            }, 150);
            var t = e + "_Product Filter";
            try{
                if( typeof s !== "undefined" ) {
                    s.linkTrackVars = "events";
                    s.linkTrackEvents = "event29";
                    s.events = "event29";
                    s.tl(this, "o", t);
                }
            } catch(e) {

            }
            
        }
    }
    function l() {
        t.not(".slick-initialized").slick({
            mobileFirst: !0,
            arrows: !0,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
            dots: !1,
            adaptiveHeight: !1,
            fade: !0,
            cssEase: "linear",
            asNavFor: a,
            centerMode: !0,
            responsive: [{
                breakpoint: 620,
                settings: {
                    arrows: !1
                }
            }]
        }),
        a.not(".slick-initialized").slick({
            mobileFirst: !0,
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: t,
            centerMode: !1,
            focusOnSelect: !0,
            vertical: !0,
            adaptiveHeight: !1,
            arrows: !1,
            dots: !1,
            verticalSwiping: !0
        }),
        r(),
        i(),
        $(".ProductHeader__carouselNavItem").length < 2 ? ($(".ProductHeader__carouselNavItem").hide(),
        $(".ProductHeader__carouselItemDescription").hide()) : ($(".ProductHeader__carouselNavItem").show(),
        $(".ProductHeader__carouselItemDescription").show())
    }
    $(".ProductHeader__carouselNav, .ProductHeader__carousel").on("afterChange", function() {
        r()
    }),
    $(".ProductHeader__whereToBuyLink").click(function(e) {
        e.preventDefault(),
        $(window).scrollTo(".WhereToBuy", {
            offset: -100,
            duration: 300
        })
    }),
    $(document).ready(function() {
        l(),
        e = $(window).width()
    }),
    $(window).on("resize", function() {
        $(window).width !== e && (e = $(window).width(),
        i()),
        l(),
        e = $(window).width()
    })
}(),
function() {
    "use strict";
    $(".MuseArticleCallout__articleWrapper").on("mouseenter", function() {
        var e = $(this);
        setTimeout(function() {
            e.addClass("hovered")
        }, 350)
    }),
    $(".MuseArticleCallout__articleWrapper").on("mouseleave", function() {
        $(this).removeClass("hovered")
    })
}(),
function() {
    "use strict";
    $(".MuseTopFive__articleWrapper").on("mouseenter", function() {
        var e = $(this);
        setTimeout(function() {
            e.addClass("hovered")
        }, 350)
    }),
    $(".MuseTopFive__articleWrapper").on("mouseleave", function() {
        $(this).removeClass("hovered")
    })
}(),
function(v) {
    "use strict";
    var g = []
      , w = []
      , m = (new ColorThief,
    "");
    function r() {
        var e = new RegExp("(?:(?:https?://[^/]+)?(?:/editor.html)?/content/wcm_kao/sites/kaousa/www-johnfrieda-com/)([^/]+)/([^/]+)(?:/.*)?")
          , t = new Array
          , a = ""
          , i = "";
        return null != (t = $("body").attr("data-content-path").match(e)) && 0 < t.length && (a = t[1],
        i = t[2]),
        {
            countryCode: a,
            languageCode: i
        }
    }
    function e() {
        var u = r()
          , e = "/content/dam/sites/kaousa/www-johnfrieda-com/" + u.countryCode + "/" + u.languageCode + "/articles.json";
        $.getJSON(e, function(e) {
            var s, c, n = 0;
            $.each(e.ArticlesArray, function(e, t) {
                if (n < 9) {
                    if (c = t.id,
                    -1 == $.inArray(c, g)) {
                        n++,
                        g.push(c);
                        var a = t.tag.split(",")
                          , i = "";
                        if (null != a && 0 < a.length)
                            for (var r = 0; r < a.length; r++)
                                i = i + a[r].toLowerCase() + " ";
                        var l = "www.johnfrieda.com" == v.location.hostname ? t.articlePagePath.replace(/\/content\/wcm_kao\/sites\/kaousa\/www-johnfrieda-com\/..\/../g, "/" + u.languageCode + "-" + u.countryCode.toUpperCase()) : t.articlePagePath
                          , o = v.location.protocol + "//" + v.location.hostname + (location.port ? ":" + location.port : "") + l + ".html";
                        s = $('<div class="MuseArticleGallery__article jf-shariff-newsCred" data-article-type="' + i + '" data-uid="' + t.id + '" data-article-title="' + t.title + '" data-article-image-url="' + t.imageUrl + '" data-article-path="' + o + '"><a class="MuseArticleGallery__link" href="' + l + '.html"><div class="MuseArticleGallery__overlayWrap"><span class="articleLinkBackgroundOverlay"></span><img class="articleLinkImage" src="' + t.imageUrl + '" alt=""><span class="MuseArticleGallery__category">' + t.tag + '</span></div><div class="MuseArticleGallery__textOverlay"><h3 class="MuseArticleGallery__title u-ctaArrow">' + t.title + "</h3></div></a></div>"),
                        w.push(s.find(".articleLinkImage")[0]),
                        $(".MuseArticleGallery__gridGallery").append(s),
                        v.jfAPI.createShariffButtons($(".MuseArticleGallery__article").last())
                    }
                } else if (8 < n)
                    return !1
            }),
            n = 0,
            $.each(e.ArticleTags, function(e, t) {
                $("ul.MuseArticleGallery__filters").append('<li data-filter="' + t.toLowerCase() + '" class="MuseArticleGallery__filter"><a href="#">' + t + "</a></li>")
            }),
            $(".MuseArticleGallery__filter").first().addClass("active")
        })
    }
    $(document).ready(function() {
        $("section").hasClass("js-articlelisting-sorting-execute") && (e(),
        $(".MuseArticleGallery__article").each(function() {
            g.push($(this).data("id"))
        }),
        setTimeout(function() {
            m = $(".MuseArticleGallery__gridGallery").imagesLoaded(function() {
                m.isotope({
                    itemSelector: ".MuseArticleGallery__article",
                    layoutMode: "fitRows",
                    percentPosition: !0,
                    fitRows: {
                        gutter: ".MuseArticleGallery__gutter-size"
                    }
                })
            })
        }, 1e3),
        $(".MuseArticleGallery__filters").click(function(e) {
            e.preventDefault()
        }),
        $(".MuseArticleGallery__filters").on("click", ".MuseArticleGallery__filter:not(.active)", function(e) {
            var t = $(this).text().trim();
            try {
                $(".MuseArticleGallery__filter.active").removeClass("active");
                if( typeof s !== "undefined" ) {
                    s.linkTrackVars = "events";
                    s.linkTrackEvents = "event23";
                    s.events = "event23";
                    s.tl(this, "o", "Select " + t + "_Content Filter");
                }
            }catch(e) {
            }
            var a = $("#loadMore").val();
            $(".MuseArticleGallery__loadMore.MuseArticleGallery__loadMoreDisabled").removeClass("MuseArticleGallery__loadMoreDisabled").text(a),
            $(this).addClass("active"),
            e.preventDefault();
            var i = $(this).data("filter");
            "*" == i ? m.isotope({
                filter: "*"
            }) : (m.isotope({
                filter: function() {
                    var e = $(this).attr("data-article-type").toLowerCase().split(" ")
                      , t = i.toLowerCase();
                    return 0 < e.length && (e = $.map(e, function(e) {
                        return $.trim(e)
                    })),
                    -1 != $.inArray(t, e)
                }
            }),
            setTimeout(function() {
                var p, t, f, e, a = $("div.MuseArticleGallery__article:visible").length;
                if (a < 8) {
                    var i = $(".MuseArticleGallery__filter.active").data("filter");
                    p = i,
                    t = a,
                    f = r(),
                    e = "/content/dam/sites/kaousa/www-johnfrieda-com/" + f.countryCode + "/" + f.languageCode + "/articles.json",
                    $.getJSON(e, function(e) {
                        var c, n, u, d = 0, _ = 9 - t, h = [];
                        $.each(e.ArticlesArray, function(e, t) {
                            var a = t.tag.toLowerCase().split(",");
                            if ($.each(a, function(e, t) {
                                h[t] = isNaN(h[t]) ? 1 : h[t] + 1
                            }),
                            "*" == p && d <= _) {
                                if (n = t.id,
                                -1 == $.inArray(n, g)) {
                                    d++,
                                    g.push(n);
                                    var i = t.tag.split(",")
                                      , r = "";
                                    if (null != i && 0 < i.length)
                                        for (var l = 0; l < i.length; l++)
                                            r = r + i[l].toLowerCase() + " ";
                                    var o = "www.johnfrieda.com" == v.location.hostname ? t.articlePagePath.replace(/\/content\/wcm_kao\/sites\/kaousa\/www-johnfrieda-com\/..\/../g, "/" + f.languageCode + "-" + f.countryCode.toUpperCase()) : t.articlePagePath
                                      , s = v.location.protocol + "//" + v.location.hostname + (location.port ? ":" + location.port : "") + o + ".html";
                                    c = $('<div class="MuseArticleGallery__article jf-shariff-newsCred" data-article-type="' + r + '" data-uid="' + t.id + '" data-article-title="' + t.title + '" data-article-image-url="' + t.imageUrl + '" data-article-path="' + s + '"><a class="MuseArticleGallery__link" href="' + o + '.html"><div class="MuseArticleGallery__overlayWrap"><span class="articleLinkBackgroundOverlay"></span><img class="articleLinkImage" src="' + t.imageUrl + '" alt=""><span class="MuseArticleGallery__category">' + t.tag + '</span></div><div class="MuseArticleGallery__textOverlay"><h3 class="MuseArticleGallery__title u-ctaArrow">' + t.title + "</h3></div></a></div>"),
                                    w.push(c.find(".articleLinkImage")[0]),
                                    m.append(c).isotope("appended", c),
                                    v.jfAPI.createShariffButtons($(".MuseArticleGallery__article").last())
                                }
                            } else if (d < _)
                                u = t.tag.toLowerCase().split(","),
                                p = p.toLowerCase(),
                                n = t.id,
                                -1 !== $.inArray(p, u) && -1 == $.inArray(n, g) && (d++,
                                g.push(n),
                                o = "www.johnfrieda.com" == v.location.hostname ? t.articlePagePath.replace(/\/content\/wcm_kao\/sites\/kaousa\/www-johnfrieda-com\/..\/../g, "/" + f.languageCode + "-" + f.countryCode.toUpperCase()) : t.articlePagePath,
                                s = v.location.protocol + "//" + v.location.hostname + (location.port ? ":" + location.port : "") + o + ".html",
                                c = $('<div class="MuseArticleGallery__article  jf-shariff-newsCred" data-article-type="' + t.tag + '"data-uid="' + t.id + '"   data-article-title="' + t.title + '" data-article-image-url="' + t.imageUrl + '" data-article-path="' + s + '"><a class="MuseArticleGallery__link" href="' + o + '.html"><div class="MuseArticleGallery__overlayWrap"><span class="articleLinkBackgroundOverlay"></span><img class="articleLinkImage" src="' + t.imageUrl + '" alt=""><span class="MuseArticleGallery__category">' + t.tag + '</span></div><div class="MuseArticleGallery__textOverlay"><h3 class="MuseArticleGallery__title u-ctaArrow">' + t.title + "</h3></div></a></div>"),
                                w.push(c.find(".articleLinkImage")[0]),
                                m.append(c).isotope("appended", c),
                                v.jfAPI.createShariffButtons($(".MuseArticleGallery__article").last()));
                            else if (_ <= d)
                                return !1
                        }),
                        d = 0,
                        m.isotope("layout"),
                        setTimeout(function() {
                            m.isotope("layout")
                        }, 1e3),
                        setTimeout(function() {
                            $("canvas").hide(),
                            m.isotope("layout")
                        }, 2e3)
                    })
                }
            }, 1e3))
        }),
        $(".MuseArticleGallery__loadMore").click(function(e) {
            e.preventDefault();
            var d, _, t, a = $(".MuseArticleGallery__filter.active").data("filter");
            d = a,
            _ = r(),
            t = "/content/dam/sites/kaousa/www-johnfrieda-com/" + _.countryCode + "/" + _.languageCode + "/articles.json",
            $.getJSON(t, function(e) {
                var s, c, n, u = 0;
                if ($.each(e.ArticlesArray, function(e, t) {
                    if ("*" == d && u <= 11) {
                        if (c = t.id,
                        -1 == $.inArray(c, g)) {
                            u++,
                            g.push(c);
                            var a = t.tag.split(",")
                              , i = "";
                            if (null != a && 0 < a.length)
                                for (var r = 0; r < a.length; r++)
                                    i = i + a[r].toLowerCase() + " ";
                            var l = "www.johnfrieda.com" == v.location.hostname ? t.articlePagePath.replace(/\/content\/wcm_kao\/sites\/kaousa\/www-johnfrieda-com\/..\/../g, "/" + _.languageCode + "-" + _.countryCode.toUpperCase()) : t.articlePagePath
                              , o = v.location.protocol + "//" + v.location.hostname + (location.port ? ":" + location.port : "") + l + ".html";
                            s = $('<div class="MuseArticleGallery__article jf-shariff-newsCred" data-article-type="' + i + '" data-uid="' + t.id + '" data-article-title="' + t.title + '" data-article-image-url="' + t.imageUrl + '" data-article-path="' + o + '"><a class="MuseArticleGallery__link" href="' + l + '.html"><div class="MuseArticleGallery__overlayWrap"><span class="articleLinkBackgroundOverlay"></span><img class="articleLinkImage" src="' + t.imageUrl + '" alt=""><span class="MuseArticleGallery__category">' + t.tag + '</span></div><div class="MuseArticleGallery__textOverlay"><h3 class="MuseArticleGallery__title u-ctaArrow">' + t.title + "</h3></div></a></div>"),
                            w.push(s.find(".articleLinkImage")[0]),
                            m.append(s).isotope("appended", s),
                            v.jfAPI.createShariffButtons($(".MuseArticleGallery__article").last())
                        }
                    } else if (u <= 11)
                        n = t.tag.toLowerCase().split(","),
                        d = d.toLowerCase(),
                        c = t.id,
                        -1 !== $.inArray(d, n) && -1 == $.inArray(c, g) && (u++,
                        g.push(c),
                        l = "www.johnfrieda.com" == v.location.hostname ? t.articlePagePath.replace(/\/content\/wcm_kao\/sites\/kaousa\/www-johnfrieda-com\/..\/../g, "/" + _.languageCode + "-" + _.countryCode.toUpperCase()) : t.articlePagePath,
                        o = v.location.protocol + "//" + v.location.hostname + (location.port ? ":" + location.port : "") + l + ".html",
                        s = $('<div class="MuseArticleGallery__article  jf-shariff-newsCred" data-article-type="' + t.tag + '"data-uid="' + t.id + '"   data-article-title="' + t.title + '" data-article-image-url="' + t.imageUrl + '" data-article-path="' + o + '"><a class="MuseArticleGallery__link" href="' + l + '.html"><div class="MuseArticleGallery__overlayWrap"><span class="articleLinkBackgroundOverlay"></span><img class="articleLinkImage" src="' + t.imageUrl + '" alt=""><span class="MuseArticleGallery__category">' + t.tag + '</span></div><div class="MuseArticleGallery__textOverlay"><h3 class="MuseArticleGallery__title u-ctaArrow">' + t.title + "</h3></div></a></div>"),
                        w.push(s.find(".articleLinkImage")[0]),
                        m.append(s).isotope("appended", s),
                        v.jfAPI.createShariffButtons($(".MuseArticleGallery__article").last()));
                    else if (11 < u)
                        return !1
                }),
                u < 11) {
                    var t = $("#noMoreArticle").val();
                    $(".MuseArticleGallery__loadMore").addClass("MuseArticleGallery__loadMoreDisabled").text(t),
                    $("canvas").hide()
                }
                u = 0,
                m.isotope("layout"),
                setTimeout(function() {
                    m.isotope("layout")
                }, 1e3),
                setTimeout(function() {
                    $("canvas").hide(),
                    m.isotope("layout")
                }, 2e3)
            })
        }))
    }),
    $(".MuseArticleGallery__article").on("mouseenter", function() {
        var e = $(this);
        setTimeout(function() {
            e.addClass("hovered")
        }, 350)
    }),
    $(".MuseArticleGallery__article").on("mouseleave", function() {
        $(this).removeClass("hovered")
    }),
    v.loadArticles = e
}(window),
function() {
    "use strict";
    function e() {
        1025 <= $(window).width() ? window.kaoAPI.isAuthorring ? $(".is-authorring .Article__topWrap").height("600px") : $(".Article__topWrap").height(.67 * $(window).height()) : 641 <= $(window).width() ? window.kaoAPI.isAuthorring ? $(".is-authorring .Article__topWrap").height("600px") : $(".Article__topWrap").height(.38 * $(window).height()) : window.kaoAPI.isAuthorring ? $(".is-authorring .Article__topWrap").height("300px") : $(".Article__topWrap").css("height", "auto")
    }
    $(document).ready(function() {
        e()
    }),
    $(window).resize(function() {
        e()
    })
}(),
function() {
    "use strict";
    function e() {
        if ($(window).width() <= 1025)
            var e = 10;
        else
            e = 30;
        var t = $(".MuseArticleCallout__textOverlay").outerHeight() + e + "px";
        $(".MuseArticleCallout__category").css("bottom", t)
    }
    $(document).ready(function() {
        e()
    }),
    $(window).resize(function() {
        e()
    })
}();
